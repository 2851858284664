import './style.css';
import React from 'react';
import { Routes } from './router';
import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useAuthentication } from './shared/context/authContext';
import { useThemeContext } from './shared/context/themeContext';
import Snackbar from '@/shared/components/Snackbar';
import httpClient from '@/core/client/http.client';
import UserApi from '@/shared/api/user/user.api';

function App() {
  const { authenticated, setAuthenticated, setUser } = useAuthentication();
  const { theme } = useThemeContext();

  const [isReady, setIsReady] = React.useState<boolean>(false);

  const fetchUser = async () => {
    try {
      const response = await UserApi.fetchCurrentUser();

      setAuthenticated(!!response.data);
      setUser(response.data);
    } catch (error) {
      httpClient.clearToken();
      setAuthenticated(false);
    } finally {
      setIsReady(true);
    }
  };

  React.useEffect(() => {
    const accessToken = httpClient.getToken();

    if (accessToken) {
      fetchUser();
    } else {
      httpClient.clearToken();
      setIsReady(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box className="App">
        {isReady ? (
          <Routes isAuthorized={authenticated} />
        ) : (
          <Box
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h1" marginBottom={4}>
                Loading...
              </Typography>
              <CircularProgress />
            </Box>
          </Box>
        )}
      </Box>

      <Snackbar />
    </ThemeProvider>
  );
}

export default App;
