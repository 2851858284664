import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { NavLink } from 'react-router-dom';

import Headers from '../Header';
import navigation from './navigations.const';

type AppBarProps = {
  onChangeMode?: () => void;
};

interface HeaderProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Header = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<HeaderProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Link = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',

  '&.active > div': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export function AppBar(props: AppBarProps) {
  const { onChangeMode } = props;

  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  const handleToggleOpen = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <>
      <Header
        position="fixed"
        open={open}
        sx={{
          backgroundColor:
            theme.palette.mode === 'dark' ? '' : 'rgba(255,255,255,0.9)',
          backdropFilter: 'blur(8px)',
          boxShadow: 'none',
          borderBottom: 'thin solid #E5EAF2',
        }}
      >
        <Headers
          open={open}
          handleChangeMode={onChangeMode}
          handleToggleOpen={handleToggleOpen}
        />
      </Header>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src="./favicon.png" alt="InkHub Admin" width="50px" />
          <Typography variant="h6" sx={{ ml: 1 }}>
            INKHUB Admin
          </Typography>
          <IconButton onClick={handleToggleOpen}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navigation.map(item => (
            <ListItem disablePadding key={item.title} sx={{ display: 'block' }}>
              <Link to={item.path}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
