import { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useNotify } from '@/shared/context/notifyContext';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import { TOnRemoveItem } from '@/shared/types/methods.types';
import { Tid } from '@/shared/types/promises.types';

import ContentLayout from '@/shared/layouts/ContentLayout';
import SearchBlock from '@/shared/components/SearchBlock';

import UserDataApi from './api/users.api';
import { TFormParams, TGetUserParams, TUserItem } from './types';
import UsersList from './components/UsersList';
import roles, { RoleType } from './roles';

import UserForm from './forms/users.form';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';

type onRemoveUser = TOnRemoveItem;

const UsersContainer = () => {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<TGetUserParams>({});
  const [offset] = useState<number>(10);

  const [pages, setPages] = useState<number>(0);

  const [users, setUsers] = useState<TUserItem[]>([]);
  const [selectedUser, setSelectedUser] = useState<TUserItem | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUserForm, setLoadingUserForm] = useState<boolean>(false);

  const [selectedRole, setSelectedRole] = useState<number>(
    queries.role_id || 0,
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const handleClose = () => {
    setOpenModal(false);
    setLoadingUserForm(false);
    setSelectedUser(null);
    setRemoveModal(false);
  };

  const handleOpenRemoveModal = (id: Tid) => {
    setRemoveModal(true);
    setOpenModal(true);
    setSelectedUser(users.find(user => user.id === id) || null);
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedUser(null);
  };

  const handleOpenEditModal = (id: Tid) => {
    setOpenModal(true);
    setSelectedUser(users.find(user => user.id === id) || null);
  };

  const fetchUsers = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);

      try {
        const { data, total_items } = await UserDataApi.getUserList(
          {
            emailQuery: queries.emailQuery,
            page: queries.page,
            order_by: queries.order_by,
            ...(queries.role_id && { role_id: queries.role_id }),

            pageCount: `${offset}`,
          },
          signal,
        );

        const total = total_items ? total_items : 0;

        setPages(Math.ceil(total / offset));

        setUsers(data);

        setEmptyRequests(prev => prev + 1);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [queries],
  );

  const onSearchUsers = (value: string) => {
    if (value.length === 0 && epmtyRequests !== 1) {
      setEmptyRequests(0);
      setQueries({ ...queries, emailQuery: value, page: '1' });
    }

    if (value.length >= 3) {
      setQueries({ ...queries, emailQuery: value, page: '1' });
      return;
    }
  };

  const onSelectUserType = (event: SelectChangeEvent<RoleType>) => {
    const selectedRole = roles.find(role => role.name === event.target.value);
    const roleId = selectedRole ? selectedRole.id : null;

    setQueries({
      ...queries,
      role_id: roleId || 0,
      page: '1',
    });

    setSelectedRole(roleId || 0);
  };

  const onSortUsers = (value: TGetUserParams['order_by']) => {
    const newSort = queries.order_by?.includes(value as string)
      ? queries.order_by.includes('-')
        ? value
        : `-${value}`
      : value;

    setQueries({
      ...queries,
      order_by: newSort as TGetUserParams['order_by'],
      page: '1',
    });
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  const handleCreateUser = async (form: TFormParams) => {
    setLoadingUserForm(true);

    try {
      await UserDataApi.createUser(form);

      fetchUsers();

      handleClose();

      setNotify({ message: 'User created', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingUserForm(false);
    }
  };

  const handleUpdateUser = async (id: Tid, form: TFormParams) => {
    setLoadingUserForm(true);

    try {
      await UserDataApi.updateUser(id, form);

      fetchUsers();

      handleClose();

      setNotify({ message: 'User updated', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingUserForm(false);
    }
  };

  const onRemoveUser: onRemoveUser = async () => {
    if (!selectedUser) return;

    setLoadingUserForm(true);
    try {
      await UserDataApi.removeUser(selectedUser.id);

      fetchUsers();

      handleClose();

      setNotify({ message: 'User deleted', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingUserForm(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchUsers(controller.signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const memoizedUsers = useMemo(() => users, [users]);

  return (
    <ContentLayout
      title="Users"
      buttonTitle="Create new user"
      removeTitleModal="Remove user"
      removeTitleMessage={`Are you sure you want to remove user ${selectedUser?.first_name}?`}
      loading={loading}
      formLoading={loadingUserForm}
      openModal={openModal}
      isRemoveModal={!!removeModal}
      selectedItem={selectedUser}
      onRemoveItem={onRemoveUser}
      onCloseModal={handleClose}
      onCreate={handleOpenCreateModal}
      form={
        <UserForm
          loading={loadingUserForm}
          user={selectedUser}
          onCancel={handleClose}
          onCreate={handleCreateUser}
          onUpdate={handleUpdateUser}
        />
      }
    >
      <SearchBlock
        value={queries.emailQuery || ''}
        placeholder="Search user by email"
        onInput={onSearchUsers}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />

      <Select
        value={roles.find(role => role.id === selectedRole)?.name || ''}
        label="Role"
        sx={{ marginBottom: 4, minWidth: 200 }}
        onChange={onSelectUserType}
      >
        {roles.map(role => (
          <MenuItem key={role.id} value={role.name}>
            {role.name}
          </MenuItem>
        ))}
      </Select>

      <UsersList
        loading={loading}
        params={queries}
        users={memoizedUsers}
        pages={pages}
        onSort={onSortUsers}
        onDelete={handleOpenRemoveModal}
        onEdit={handleOpenEditModal}
        onChangePage={onChangePage}
      />
    </ContentLayout>
  );
};

export default UsersContainer;
