import { useState } from 'react';
import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { TWebsiteUpdateForm, WebsiteFormType } from '../types';

import FormModal from '@/shared/components/Forms/FormModal';
import { WebsiteItemType } from '@/shared/api/websites/websites.api.types';

import useValidate, {
  Rules,
  urlPattern,
} from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';
import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';
import DebounceInput from '@/shared/components/DebounceInput';

type Props = {
  website?: WebsiteItemType | null | undefined;
  companies: CompanyItemType[];
  company: CompanyItemType | null;
  loading: boolean;
  loadingCompany: boolean;
  onUpdate: (form: TWebsiteUpdateForm) => void;
  onCreate: (form: WebsiteFormType) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
  onSearchCompanies: (query: string) => void;
};

const CompanyForm = (props: Props) => {
  const {
    website,
    companies,
    company,
    onCancel,
    onUpdate,
    onCreate,
    loading,
    onSearchCompanies,
  } = props;

  const [isEdit] = useState<boolean>(!!website);

  const [form, setForm] = useState<WebsiteFormType>({
    url: website?.url || '',
    company_id: website?.company_id || null,
    name: 'some info',
  });

  const rules: Rules<WebsiteFormType> = {
    url: {
      required: true,
      maxLength: 250,
      urlPattern,
    },

    name: {
      required: true,
      maxLength: 250,
    },

    company_id: {
      required: true,
    },
  };

  const { dirty, touch, errors } = useValidate<WebsiteFormType>(form, rules);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    if (touch()) {
      return;
    }

    if (isEdit) {
      onUpdate({ id: website?.id || '', form });
    } else {
      onCreate(form);
    }
  };

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectCompany = (company: CompanyItemType) => {
    setForm(prevForm => ({
      ...prevForm,
      company_id: company?.id || null,
    }));

    if (dirty) {
      touch();
    }
  };

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} Website`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <TextField
          placeholder="Website url"
          value={form.url}
          name="url"
          onInput={onInput}
          error={dirty && errors.url.dirty}
          helperText={dirty && mappingErrors(errors?.url?.message)}
        />
      </FormControl>

      <FormControl>
        <Autocomplete
          options={companies}
          getOptionLabel={(option: CompanyItemType) => option.name}
          defaultValue={form.company_id ? company : null}
          onChange={(_, value) => onSelectCompany(value as CompanyItemType)}
          renderInput={params => (
            <DebounceInput
              {...params}
              handleDebounce={onSearchCompanies}
              debounceTimeout={300}
              label="Company"
              placeholder="Company"
            />
          )}
          noOptionsText="No company"
        />

        {dirty && errors.company_id.dirty && (
          <Typography
            variant="subtitle2"
            display="block"
            marginTop="4px"
            lineHeight={1.2}
            color="error"
            ml={1.75}
          >
            {mappingErrors(errors.company_id.message)}
          </Typography>
        )}
      </FormControl>
    </FormModal>
  );
};

export default CompanyForm;
