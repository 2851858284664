import httpClient from '@/core/client/http.client';
import {
  GetGroupedUsersHistoryMethodType,
  GetUsersHistoryMethodType,
} from '../users-history.types';

const fetchUsersHistory: GetUsersHistoryMethodType = async params => {
  return httpClient.get('/user-history/', {
    params,
  });
};

const fetchGroupedUsersHistory: GetGroupedUsersHistoryMethodType =
  async params => {
    return httpClient.get('/user-history/', {
      params,
    });
  };

const methods = {
  fetchUsersHistory,
  fetchGroupedUsersHistory,
};

export default methods;
