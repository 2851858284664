import httpClient from '@/core/client/http.client';

import { GetTypesItems } from '@/shared/api/types/types.api';
import { TResponse, Tid } from '@/shared/types/promises.types';
import { TTypeItem } from '@/shared/api/types/types.api.types';

import { TCreateType, TDeleteType, TTypeForm, TUpdateType } from '../types';

const createType: TCreateType = async form => {
  return httpClient.post<TTypeForm, TResponse<TTypeItem>>(
    '/resource-type',
    form,
  );
};

const updateType: TUpdateType = async (id, form) => {
  return httpClient.put<TTypeForm, TResponse<TTypeItem>>(
    `/resource-type/${id}`,
    form,
  );
};

const deleteType: TDeleteType = async (id: Tid) => {
  return httpClient.delete(`/resource-type/${id}`);
};

const typesApi = {
  getTypes: GetTypesItems,
  createType,
  updateType,
  deleteType,
};

export default typesApi;
