import { ResourcesItemType } from '../types';
import { Avatar, Box, Chip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableElements from '../../../shared/components/StyledTableCell';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Tid } from '@/shared/types/promises.types';

type Props = ResourcesItemType & {
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
  onEditPreviews?: (id: Tid) => void;
};

export default function CategoryItem(props: Props) {
  const {
    id,
    name,
    // short_description,
    resource_type,
    image_path,
    company,
    keywords,
    onEdit,
    onDelete,
    onEditPreviews,
  } = props;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handlePreviews = () => {
    if (onEditPreviews) {
      onEditPreviews(id);
    }
  };

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell>
        <Avatar
          alt={name}
          src={image_path}
          variant="rounded"
          sx={{
            width: 100,
            height: 'auto',
            marginRight: 2,
            maxHeight: '220px',
          }}
        />
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell
        textProps={{
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        {name}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell>
        <Box display="flex" alignItems="center">
          <Avatar
            alt={company.name}
            src={company.image_path}
            variant="rounded"
            sx={{ width: 56, height: 56, marginRight: 2 }}
          />
          {company.name}
        </Box>
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {resource_type}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell>
        {keywords.map((keyword, index) => (
          <Chip key={index} label={keyword} sx={{ margin: 0.25 }} />
        ))}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        <Box display="flex">
          <IconButton onClick={handlePreviews}>
            <AddPhotoAlternateIcon color="action" />
          </IconButton>

          <IconButton onClick={handleEdit}>
            <EditIcon color="success" />
          </IconButton>

          <IconButton onClick={handleRemove}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
