import React, { createContext, useContext, useState, FC } from 'react';
import { createTheme, Theme } from '@mui/material/styles';
import themeState from '@/core/theme/theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

interface ThemeContextType {
  toggleTheme: () => void;
  theme: Theme;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const selectedMode: 'light' | 'dark' =
    (localStorage.getItem('mode') as 'light' | 'dark') || 'light';

  const defaultMode: boolean = selectedMode === 'dark';

  const [isDarkMode, setIsDarkMode] = useState<boolean>(defaultMode);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => {
      localStorage.setItem('mode', prevMode ? 'light' : 'dark');
      return !prevMode;
    });
  };

  const theme: Theme = createTheme({
    ...themeState,
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};
