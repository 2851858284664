import React, { useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DeleteIcon from '@mui/icons-material/Delete';

import FormModal from '@/shared/components/Forms/FormModal';
import { ResourcesItemType } from '../types';
import ImageLoader from '@/shared/components/FormImageLoader';

type Props = {
  loading: boolean;
  resource?: ResourcesItemType | null;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
  onSumbit(previews: string[], deleted?: number[]): void;
};

function ResourceImagesForm({ loading, resource, onCancel, onSumbit }: Props) {
  const [previews, setPreviews] = React.useState<string[]>([]);

  const [resourcesPreviews] = React.useState<
    {
      image_id: number;
      image_path: string;
    }[]
  >([...(resource?.pdf_file_images || [])]);

  const [deletedPreviews, setDeletedPreviews] = React.useState<number[]>([]);

  const handleChange = (value: string) => {
    setPreviews([...previews, value]);
  };

  const handleRemoveImage = (preview: string) => () => {
    const index = previews.indexOf(preview);
    if (index !== -1) {
      setPreviews([...previews.slice(0, index), ...previews.slice(index + 1)]);
    }

    const imageId = resourcesPreviews.find(
      ({ image_path }) => image_path === preview,
    )?.image_id;

    if (imageId) {
      setDeletedPreviews([...deletedPreviews, imageId]);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // if (previews.length === 0) {
    //   return;
    // }

    const deleted = deletedPreviews.length > 0 ? deletedPreviews : undefined;

    const newPreviews = previews.filter(
      (preview, index) =>
        index >= resourcesPreviews.length ||
        preview !== resourcesPreviews[index].image_path,
    );

    onSumbit(newPreviews, deleted);
  };

  useEffect(() => {
    if (resource) {
      setPreviews([...resourcesPreviews.map(({ image_path }) => image_path)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  return (
    <FormModal
      loading={loading}
      title={`Preview Images`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(5, 1fr)'}
        columnGap={2}
      >
        {previews.map((preview, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <IconButton
              aria-label="delete"
              size="medium"
              color="error"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1,
                color: 'white',
              }}
              onClick={handleRemoveImage(preview)}
            >
              <DeleteIcon fontSize="inherit" color="error" />
            </IconButton>
            <img
              src={preview}
              alt={`Preview ${index}`}
              style={{
                width: '100%',
                height: '134px',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          </Box>
        ))}
        {previews.length < 5 && (
          <ImageLoader
            value=""
            hideRemoveIcon
            accept=".jpg, .jpeg, .png"
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'transparent',
            }}
            trigger={
              <IconButton
                sx={{
                  width: '100%',
                  height: '134px',
                  borderRadius: '10px',
                  border: '1px dashed #E0E0E0',
                  pointerEvents: 'none',
                }}
              >
                <LocalHospitalIcon
                  sx={{
                    width: '32px',
                    height: '32px',
                  }}
                />
              </IconButton>
            }
            onChange={handleChange}
          />
        )}
      </Box>
    </FormModal>
  );
}

export default ResourceImagesForm;
