import {
  Box,
  Button,
  // CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import React from 'react';
import RemoveForm from '../components/Forms/RemoveModalItem';

type Props = {
  title: string | React.ReactNode;
  buttonTitle?: string;
  loading: boolean;
  formLoading?: boolean;
  children: React.ReactNode;
  form?: React.ReactNode;
  openModal?: boolean;
  isRemoveModal?: boolean;
  selectedItem?: object | null;
  removeTitleModal?: string;
  removeTitleMessage?: string;
  onCreate?: () => void;
  onCloseModal?: () => void;
  onRemoveItem?: () => Promise<void>;
};

const ContentLayout = (props: Props) => {
  const {
    isRemoveModal,
    title,
    buttonTitle,
    children,
    form,
    formLoading,
    openModal,
    removeTitleModal,
    removeTitleMessage,
    onCreate,
    onCloseModal,
    onRemoveItem,
  } = props;

  const isHasModalFrom =
    removeTitleModal &&
    removeTitleMessage &&
    isRemoveModal &&
    onCloseModal &&
    onRemoveItem;

  return (
    <Box>
      <Box display="flex" alignItems="center" marginBottom={10}>
        <Typography variant="h1" marginRight={2}>
          {title}
        </Typography>
        {buttonTitle && onCreate && (
          <Button
            variant="contained"
            sx={{ marginLeft: 'auto' }}
            onClick={onCreate}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
      {children}

      {/* {loading ? (
        <Box
          height={300}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )} */}

      {openModal && (
        <Modal
          keepMounted
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          sx={{
            overflow: 'auto',
          }}
        >
          <Box>
            {isHasModalFrom ? (
              <RemoveForm
                title={removeTitleModal}
                message={removeTitleMessage}
                loading={formLoading || false}
                onCancel={onCloseModal}
                onRemove={onRemoveItem}
              />
            ) : (
              form
            )}
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default ContentLayout;
