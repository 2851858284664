import { useEffect, useMemo, useState } from 'react';
import { useNotify } from '@/shared/context/notifyContext';

import { Tid } from '@/shared/types/promises.types';
import { TTypeItem, TTypesGetParams } from '@/shared/api/types/types.api.types';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';

import {
  TFetchItem,
  TOnCreateItem,
  TOnEditItem,
  TOnRemoveItem,
} from '@/shared/types/methods.types';

import { TTypeForm } from './types';

import TypeDataApi from './api/types.api';

import ContentLayout from '@/shared/layouts/ContentLayout';
import SearchBlock from '@/shared/components/SearchBlock';

import TypesList from './components/TypesList';
import TypeForm from './forms/type.form';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';

type TFetchTypes = TFetchItem;
type onRemoveType = TOnRemoveItem;
type onCreateType = TOnCreateItem<TTypeForm>;
type onEditType = TOnEditItem<TTypeForm>;

function TypesContainer() {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<TTypesGetParams>({});
  const [offset] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);

  const [types, setTypes] = useState<TTypeItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingTypeForm, setLoadingTypeForm] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<TTypeItem | null>(null);

  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const onClearModalState = () => {
    setRemoveModal(false);
    setSelectedType(null);
    setOpenModal(false);
  };

  const onSetSelectedType = (id: Tid) => {
    const type = types.find(type => type.id === id);

    setSelectedType(type || null);
  };

  const handleClose = () => {
    onClearModalState();
    setLoadingTypeForm(false);
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedType(null);
  };

  const handleOpenRemoveModal = (id: Tid) => {
    setRemoveModal(true);
    setOpenModal(true);
    onSetSelectedType(id);
  };

  const handleOpenEditModal = (id: Tid) => {
    setOpenModal(true);
    onSetSelectedType(id);
  };

  const fetchTypes: TFetchTypes = async () => {
    setLoading(true);
    try {
      const { data, total_items } = await TypeDataApi.getTypes({
        ...queries,
        pageCount: `${offset}`,
      });

      const total = total_items ? total_items : 0;

      setPages(Math.ceil(total / offset));

      setTypes(data);

      setEmptyRequests(prev => prev + 1);
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoading(false);
    }
  };

  const onSearchTypes = (query: string) => {
    if (query.length === 0 && epmtyRequests !== 1) {
      setQueries({ ...queries, query: undefined, page: '1' });
      return;
    }

    if (query.length >= 3) {
      setQueries({ ...queries, query, page: '1' });
      return;
    }
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  // const onSortTypes = (field: string) => {
  //   const sort = queries.sort === field ? `-${field}` : field;

  //   setQueries({ ...queries, sort, page: 0 });
  // };

  const onCreateType: onCreateType = async form => {
    setLoadingTypeForm(true);
    try {
      const response = await TypeDataApi.createType(form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message: 'Duplicate key value violates unique constraint',
        });

        return;
      }

      setTypes([response.data, ...types]);

      onClearModalState();

      setNotify({
        type: 'success',
        message: 'Type created',
      });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingTypeForm(false);
    }
  };

  const onEditType: onEditType = async (id, form) => {
    setLoadingTypeForm(true);
    try {
      const response = await TypeDataApi.updateType(id, form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      setTypes(types.map(type => (type.id === id ? response.data : type)));

      onClearModalState();

      setNotify({
        type: 'success',
        message: 'Type updated',
      });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingTypeForm(false);
    }
  };

  const onRemoveType: onRemoveType = async () => {
    if (!selectedType) return;

    setLoadingTypeForm(true);
    try {
      await TypeDataApi.deleteType(selectedType.id);

      setTypes(types.filter(type => type.id !== selectedType.id));

      onClearModalState();

      setNotify({
        type: 'success',
        message: 'Type removed',
      });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingTypeForm(false);
    }
  };

  useEffect(() => {
    fetchTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const memoizedTypes = useMemo(() => types, [types]);

  return (
    <ContentLayout
      title="Types"
      buttonTitle="Create new type"
      removeTitleModal="Remove type"
      removeTitleMessage={`Are you sure you want to remove type ${selectedType?.name}?`}
      loading={loading}
      formLoading={loadingTypeForm}
      openModal={openModal}
      isRemoveModal={!!removeModal}
      selectedItem={selectedType}
      onRemoveItem={onRemoveType}
      onCloseModal={handleClose}
      onCreate={handleOpenCreateModal}
      form={
        <TypeForm
          loading={loadingTypeForm}
          type={selectedType}
          onCancel={handleClose}
          onCreate={onCreateType}
          onUpdate={onEditType}
        />
      }
    >
      <SearchBlock
        value={queries.query || ''}
        placeholder="Search type by name"
        onInput={onSearchTypes}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />
      <TypesList
        types={memoizedTypes}
        pages={pages}
        params={queries}
        loading={loading}
        onDelete={handleOpenRemoveModal}
        onEdit={handleOpenEditModal}
        onChangePage={onChangePage}
      />
    </ContentLayout>
  );
}

export default TypesContainer;
