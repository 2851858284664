import { TLogin, LoginRespone } from './../types.d';
import httpClient from '@/core/client/http.client';

type Login = (login: TLogin) => Promise<LoginRespone>;

const login: Login = async login => {
  const response = await httpClient.post<TLogin, LoginRespone>(
    '/admin-login',
    login,
  );

  return response;
};

const methods = {
  login,
};

export default methods;
