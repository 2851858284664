import httpClient from '@/core/client/http.client';

import { TResponse } from '@/shared/types/promises.types.d';
import { CompanyItemType, FetchCompaniesListType } from './compnaies.api.types';

export const fetchCompaniesList: FetchCompaniesListType = async (
  params,
  signal,
) => {
  return httpClient.get<TResponse<CompanyItemType[]>>('/companies', {
    params,
    signal,
  });
};

const fetchCompanyById = async (
  id: string | number,
): Promise<TResponse<CompanyItemType>> => {
  return httpClient.get(`/company/${id}`);
};

const methods = {
  fetchCompaniesList,
  fetchCompanyById,
};

export default methods;
