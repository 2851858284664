import httpClient from '@/core/client/http.client';

import { TResponse } from '@/shared/types/promises.types.d';
import { FetchKeywordsListType, KeywordItemType } from './keywords.api.types';

export const fetchKeywordsList: FetchKeywordsListType = async params => {
  return httpClient.get<TResponse<KeywordItemType[]>>('/keywords', { params });
};

const methods = {
  fetchKeywordsList,
};

export default methods;
