import { Box, Button, IconButton, Modal, SxProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import React, { createRef, useState, useRef } from 'react';
import { useNotify } from '@/shared/context/notifyContext';
// import { useDropzone } from 'react-dropzone';

const styles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 700,
  bgcolor: 'background.paper',
  borderRadius: 0,
  boxShadow: 24,
  p: 2,
};

type Props = {
  value: string;
  aspectRatio?: number;
  defaultImage?: string;
  accept?: string;
  trigger?: React.ReactNode;
  hideRemoveIcon?: boolean;
  sx?: SxProps;
  onChange: (value: string) => void;
};

const FormImageLoader: React.FC<Props> = props => {
  const {
    value,
    onChange,
    sx,
    aspectRatio,
    defaultImage,
    accept,
    trigger,
    hideRemoveIcon,
  } = props;

  const { setNotify } = useNotify();

  const [image, setImage] = useState('');

  const fileInputRef = useRef(null);
  const [cropData, setCropData] = useState(value);
  const cropperRef = createRef<ReactCropperElement>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];

    const filesEnds = file.name.split('.').pop();

    if (accept && !accept.includes(filesEnds)) {
      setNotify({
        message: 'Invalid file format. Please upload a valid file.',
        type: 'error',
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
    };
    reader.readAsDataURL(files[0]);
  };

  const onClearForm = () => {
    setImage('');
    (fileInputRef.current as any).value = '';
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      setCropData(data);

      // const base64 = data.split(',')[1];
      // const decodedData = atob(base64);

      onChange(data);

      onClearForm();
    }
  };

  const onRemoveImage = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setCropData('');
    onChange('');
  };

  return (
    <Box display="inline-block">
      <Box
        component="label"
        display="block"
        width={200}
        height={200}
        bgcolor="grey.200"
        borderRadius={2}
        sx={{
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative',
          ...sx,
        }}
      >
        <input
          hidden
          type="file"
          onChange={handleChange}
          ref={fileInputRef}
          accept={accept || 'image/*'}
        />
        {trigger || (
          <img
            src={cropData || defaultImage || './images/default-logo.png'}
            alt="preview"
            width={100}
            height={100}
            style={{ width: '100%', height: 'auto' }}
          />
        )}

        {cropData && !hideRemoveIcon && (
          <IconButton
            aria-label="delete"
            size="medium"
            color="error"
            sx={{
              position: 'absolute',
              left: '50%',
              bottom: 1,
              transform: 'translateX(-50%)',
            }}
            onClick={onRemoveImage}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>

      {image && (
        <Modal
          keepMounted
          open={!!image}
          onClose={onClearForm}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              columnGap={3}
              width="100%"
              maxWidth={200}
              marginLeft="auto"
              marginBottom={3}
            >
              <Button
                color="success"
                variant="contained"
                size="large"
                onClick={getCropData}
              >
                Done
              </Button>
              <Button
                color="error"
                variant="contained"
                size="large"
                onClick={onClearForm}
              >
                Close
              </Button>
            </Box>
            <Cropper
              src={image}
              background={false}
              responsive={false}
              autoCropArea={1}
              checkOrientation={true}
              guides={true}
              {...{
                ref: cropperRef,
                style: { height: 400, width: '100%' },
                zoomTo: 0.2,
                initialAspectRatio: aspectRatio || 1,
                ...(aspectRatio && { aspectRatio }),
              }}
            />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default FormImageLoader;
