import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import Pagination from '@/shared/components/Pagination';
import TableElements from '@/shared/components/StyledTableCell';

import UserHistoryItem from './UserHistoryItem';

import {
  UserHistoryGetParamsType,
  GroupUserHistoryItemModel,
} from '../users-history.types';

type Props = {
  usersHistory: GroupUserHistoryItemModel[];
  params: UserHistoryGetParamsType;
  loading: boolean;
  pages: number;

  onChangePage: (offset: number) => void;
};

export default function CategoriesList(props: Props) {
  const { usersHistory, pages, params, onChangePage } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell>
                Email
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Name
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Company
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Role
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Last updated
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Total
              </TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersHistory?.map(user => (
              <UserHistoryItem key={user.email} {...user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {pages > 1 && (
        <Pagination
          pages={pages}
          page={params.offset || 1}
          onChange={(_, value) => onChangePage(value)}
        />
      )}
    </>
  );
}
