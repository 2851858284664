import './styles.css';
import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import 'react-quill/dist/quill.snow.css';

import { TSubCategoryForm } from '../types';
import { Tid } from '@/shared/types/promises.types';
import { TCategoryItem } from '@/shared/api/categories/categories.api.types.d';

import FormModal from '@/shared/components/Forms/FormModal';

import useValidate, { Rules } from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';

type Props = {
  subcategory?: TCategoryItem | null | undefined;
  categories: TCategoryItem[];
  loading: boolean;
  onCreate: (form: TSubCategoryForm) => void;
  onUpdate: (id: Tid, form: TSubCategoryForm) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
};

const CategoryForm = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { subcategory, categories, onCancel, onCreate, onUpdate, loading } =
    props;

  const [isEdit] = useState<boolean>(!!subcategory);

  const [form, setForm] = useState<TSubCategoryForm>({
    name: subcategory?.name || '',
    description: subcategory?.description || '',
    parent_id: subcategory?.parent_id || categories[0].id,
    category_type: subcategory?.category_type || categories[0].category_type,
  });

  const [selectedCategory, setSelectedCategory] = useState<
    TCategoryItem | undefined
  >(
    isEdit
      ? categories.find(category => category.id === subcategory?.parent_id)
      : categories[0],
  );

  const rules: Rules<TSubCategoryForm> = {
    name: {
      required: true,
      maxLength: 100,
    },
    description: {
      required: false,
    },

    parent_id: {
      required: true,
    },

    category_type: {
      required: true,
    },
  };

  const { dirty, touch, errors } = useValidate<TSubCategoryForm>(form, rules);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    if (touch()) {
      return;
    }

    if (isEdit && subcategory) {
      onUpdate(subcategory.id, form);
    } else {
      onCreate(form);
    }
  };

  const onInput = (value: string, type: 'name' | 'description') => {
    setForm(prevForm => ({
      ...prevForm,
      [type]: value,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectCategory = (event: SelectChangeEvent<TCategoryItem['id']>) => {
    const category = categories.find(
      category => category.id === event.target.value,
    );

    setSelectedCategory(category);

    setForm(prevForm => ({
      ...prevForm,
      parent_id: category?.id || 0,
      category_type: category?.category_type || '',
    }));

    if (dirty) {
      touch();
    }
  };

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} Subcategory`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <TextField
          placeholder="Subcategory name"
          value={form.name}
          name="name"
          error={dirty && errors.name.dirty}
          helperText={dirty && mappingErrors(errors?.name?.message)}
          onInput={event =>
            onInput((event?.target as HTMLInputElement)?.value || '', 'name')
          }
        />
      </FormControl>

      <FormControl sx={{ width: '100%', marginTop: 2 }}>
        <InputLabel id="demo-multiple-name-label">Categories</InputLabel>

        <Select
          labelId="demo-multiple-name-label"
          value={selectedCategory?.id || 0}
          label="Categories"
          onChange={onSelectCategory}
        >
          {categories.map(category => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          value={form.description}
          name="description"
          placeholder="Description"
          multiline
          rows={4}
          error={dirty && errors.description.dirty}
          helperText={dirty && mappingErrors(errors?.description?.message)}
          onInput={event =>
            onInput(
              (event?.target as HTMLInputElement)?.value || '',
              'description',
            )
          }
        />
      </FormControl>
    </FormModal>
  );
};

export default CategoryForm;
