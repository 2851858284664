export type RoleType =
  | 'All'
  | 'Chief Executive Officer'
  | 'Chief Financial Officer'
  | 'Chief Operating Officer'
  | 'Chief Technology Officer'
  | 'Chief Marketing Officer'
  | 'Chief Information Officer'
  | 'HR Director'
  | 'Sales Director'
  | 'Marketing Manager'
  | 'IT Manager'
  | 'Finance Manager'
  | 'Project Manager'
  | 'Team Lead'
  | 'Developer'
  | 'Designer'
  | 'Engineer'
  | 'Analyst'
  | 'Accountant'
  | 'Administrative Assistant'
  | 'Customer Support Representative'
  | 'Other'
  | 'User'
  | 'Company manager'
  | 'Admin'
  | 'Super Admin';

export type RolesTypes = {
  id: number;
  name: RoleType;
  value: 'user' | 'company_manager' | 'admin' | 'superadmin' | 'all';
};

const roles: RolesTypes[] = [
  {
    id: 0,
    name: 'All',
    value: 'all',
  },
  {
    id: 1,
    name: 'User',
    value: 'user',
  },
  {
    id: 2,
    name: 'Company manager',
    value: 'company_manager',
  },

  {
    id: 3,
    name: 'Admin',
    value: 'admin',
  },

  {
    id: 4,
    name: 'Super Admin',
    value: 'superadmin',
  },

  // {
  //   id: 4,
  //   name: 'Super Admin',
  // },
  // {
  //   id: 1,
  //   name: 'Chief Executive Officer',
  // },
  // {
  //   id: 2,
  //   name: 'Chief Financial Officer',
  // },
  // {
  //   id: 3,
  //   name: 'Chief Operating Officer',
  // },
  // {
  //   id: 4,
  //   name: 'Chief Technology Officer',
  // },
  // {
  //   id: 5,
  //   name: 'Chief Marketing Officer',
  // },
  // {
  //   id: 6,
  //   name: 'Chief Information Officer',
  // },
  // {
  //   id: 7,
  //   name: 'HR Director',
  // },
  // {
  //   id: 8,
  //   name: 'Sales Director',
  // },
  // {
  //   id: 9,
  //   name: 'Marketing Manager',
  // },
  // {
  //   id: 10,
  //   name: 'IT Manager',
  // },
  // {
  //   id: 11,
  //   name: 'Finance Manager',
  // },
  // {
  //   id: 12,
  //   name: 'Project Manager',
  // },
  // {
  //   id: 13,
  //   name: 'Team Lead',
  // },
  // {
  //   id: 14,
  //   name: 'Developer',
  // },
  // {
  //   id: 15,
  //   name: 'Designer',
  // },
  // {
  //   id: 16,
  //   name: 'Engineer',
  // },
  // {
  //   id: 17,
  //   name: 'Analyst',
  // },
  // {
  //   id: 18,
  //   name: 'Accountant',
  // },
  // {
  //   id: 19,
  //   name: 'Administrative Assistant',
  // },
  // {
  //   id: 20,
  //   name: 'Customer Support Representative',
  // },
  // {
  //   id: 21,
  //   name: 'Other',
  // },
];

export default roles;
