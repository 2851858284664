import { TResponse } from '@/shared/types/promises.types.d';

import { TCategoryItem } from '@/shared/api/categories/categories.api.types.d';

import {
  TSubCategoryForm,
  TCreateSubCategory,
  TUpdateSubCategory,
  TDeleteSubCategory,
} from '../types';

import httpClient from '@/core/client/http.client';
import { GetCategoriesItems } from '@/shared/api/categories/categories.api';

const createSubCategory: TCreateSubCategory = async form => {
  return httpClient.post<TSubCategoryForm, TResponse<TCategoryItem>>(
    '/category',
    form,
  );
};

const updateSubCategory: TUpdateSubCategory = async (id, form) => {
  return httpClient.put<TSubCategoryForm, TResponse<TCategoryItem>>(
    `/category/${id}`,
    form,
  );
};

const deleteSubCategory: TDeleteSubCategory = async id => {
  return httpClient.delete(`/category/${id}`);
};

const categoriesApi = {
  getSubCategories: GetCategoriesItems,
  updateSubCategory,
  createSubCategory,
  deleteSubCategory,
};

export default categoriesApi;
