import httpClient from '@/core/client/http.client';

import { TResponse } from '@/shared/types/promises.types.d';
import { TGetCategories, TCategoryItem } from './categories.api.types.d';

export const GetCategoriesItems: TGetCategories = async (params, signal) => {
  return httpClient.get<TResponse<TCategoryItem[]>>('/categories', {
    params,
    signal,
  });
};

const methods = {
  GetCategoriesItems,
};

export default methods;
