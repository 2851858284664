import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import React from 'react';

const AuthLayout: React.FC = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
