import httpClient from '@/core/client/http.client';
import { Tid } from '@/shared/types/promises.types';
import { ResourcesItemType } from './resources.types';

const fetchResourceById = async (
  id: Tid,
): Promise<{
  data: ResourcesItemType;
}> => {
  return httpClient.get(`/pdf-resources/${id}`);
};

const methods = {
  fetchResourceById,
};

export default methods;
