import { TCategoryItem } from '@/shared/api/categories/categories.api.types.d';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableElements from '@/shared/components/StyledTableCell';
import { Tid } from '@/shared/types/promises.types';

type Props = TCategoryItem & {
  categories: TCategoryItem[];
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
};

export default function CategoryItem(props: Props) {
  const {
    categories,
    id,
    name,
    category_type,
    parent_id,
    status,

    onEdit,
    onDelete,
  } = props;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell component="th" scope="row">
        {name}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {categories.find(category => category.id === parent_id)?.name ||
          'No parent'}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {category_type}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {status}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        <IconButton onClick={handleEdit}>
          <EditIcon color="success" />
        </IconButton>

        <IconButton onClick={handleRemove}>
          <DeleteIcon color="error" />
        </IconButton>
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
