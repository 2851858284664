import httpClient from '@/core/client/http.client';
// import mockData from './mock.data';

import { TPrimaryResponse, Tid } from '@/shared/types/promises.types.d';
import {
  TNotificationItem,
  TFetchNotification,
  TRemoveNotification,
  UpdateNotificationForm,
} from '../types';

const getNotifications: TFetchNotification = async params => {
  return httpClient.get<TPrimaryResponse<TNotificationItem[]>>(
    '/notifications',
    {
      params,
    },
  );
};

const getNotificationById = async (
  id: Tid,
): Promise<{
  data: TNotificationItem;
}> => {
  return httpClient.get(`/notifications/${id}`);
};

const setViewedNotification = async (id: Tid, form: UpdateNotificationForm) => {
  return httpClient.patch(`/notifications/${id}`, form);
};

const removeNotification: TRemoveNotification = async (id: Tid) => {
  return httpClient.delete(`/notifications/${id}`);
};

const methods = {
  getNotifications,
  getNotificationById,
  setViewedNotification,
  removeNotification,
};

export default methods;
