import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppBar from '../shared/features/AppBar';
import React from 'react';

const MainLayout: React.FC = () => {
  const handleChangeMode = () => {
    console.log('Change mode');
  };

  return (
    <Box display="flex" marginTop={7.5}>
      <Box component="nav">
        <AppBar onChangeMode={handleChangeMode} />
      </Box>

      <Box
        component="main"
        width="100%"
        padding={{ xs: '20px 30px', sm: '25px 50px', overflow: 'auto' }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
