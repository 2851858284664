import { useState } from 'react';

type Form<T> = Partial<
  Record<
    keyof T,
    string | string[] | boolean | Date | number | undefined | null
  >
>;

export type MessageType = {
  message: string;
};

export type Rules<T> = Record<
  keyof T,
  {
    required?: boolean | MessageType;
    minLength?: number;
    maxLength?: number;
    same?: keyof T;
    emailPattern?: RegExp;
    urlPattern?: RegExp;
    urlHttpsPattern?: RegExp;
    passwordPattern?: RegExp;
    integer?: boolean;
    text?: boolean;
  }
>;

type ErrorMessages<T> = Record<
  keyof T,
  { dirty: boolean; message: Record<string, string> }
>;

type ValidationResult<T> = {
  dirty: boolean;
  errors: ErrorMessages<T>;
};

type UseValidateResult<T> = {
  touch: () => boolean;
  combineErros: (errors: Record<string, string>) => string[];
} & ValidationResult<T>;

export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
export const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
export const integerPattern = /^\d+$/;
export const textPattern = /^[a-zA-Z\s]*$/;
export const urlPattern =
  /^(https?:\/\/)?([\w\d-]+\.)*([\w\d-]+\.[\w\d-]+)(\.[\w\d-]+)*([/?].*)?$/;

export const urlHttpsPattern = /^https:\/\//;

function formatFieldName(field: string): string {
  const camelCaseField = field.split('_').join(' ');

  return camelCaseField;
}

function useValidate<T>(form: Form<T>, rules: Rules<T>): UseValidateResult<T> {
  const [dirty, setDirty] = useState<boolean>(false);

  const [errors, setErrors] = useState<ErrorMessages<T>>(
    {} as ErrorMessages<T>,
  );

  const setInitialErrors = (): ErrorMessages<T> => {
    const initialErrors: ErrorMessages<T> = {} as ErrorMessages<T>;

    Object.keys(rules).forEach(key => {
      const field = key as keyof T;

      initialErrors[field] = {
        dirty: false,
        message: {},
      };
    });

    return initialErrors;
  };

  const validateRule = (
    key: keyof T,
  ): { dirty: boolean; message: Record<string, string> } => {
    const field = key as keyof T;
    const rule = rules[field];
    const fieldErrors: { dirty: boolean; message: Record<string, string> } = {
      dirty: false,
      message: {},
    };

    const name = formatFieldName(field as string);

    const isUndefined = typeof form[field] === 'undefined';
    const isNull = form[field] === null;
    const isBoolean = typeof form[field] === 'boolean';
    const isString = typeof form[field] === 'string';

    if (isUndefined) {
      return fieldErrors;
    }

    if (isBoolean && rule) {
      if (rule) {
        if (rule.required && form[field] === false) {
          fieldErrors.dirty = true;

          if (typeof rule.required === 'object') {
            fieldErrors.message.required = rule.required.message;
          } else {
            fieldErrors.message.required = `${name} is required`;
          }
        }
      }
    }

    if ((isNull || isString) && rule && !isUndefined) {
      if (rule.required && !form[field]) {
        fieldErrors.dirty = true;

        if (typeof rule.required === 'object') {
          fieldErrors.message.required = rule.required.message;
        } else {
          fieldErrors.message.required = `${name} is required`;
        }
      }

      if (
        rule.minLength &&
        (form[field]?.toString().length ?? 0) < rule.minLength
      ) {
        fieldErrors.dirty = true;
        fieldErrors.message.minLength = `${name} must be at least ${rule.minLength} characters long`;
      }

      if (
        rule.maxLength &&
        (form[field]?.toString().length ?? 0) > rule.maxLength
      ) {
        fieldErrors.dirty = true;
        fieldErrors.message.maxLength = `${name} must not exceed ${rule.maxLength} characters`;
      }

      if (rule.same && form[field] !== form[rule.same]) {
        fieldErrors.dirty = true;
        fieldErrors.message.same = `${name} must be the same as ${rule.same as string}`;
      }

      if (rule.passwordPattern && form[field]) {
        if (!rule.passwordPattern.test(form[field]?.toString() ?? '')) {
          fieldErrors.dirty = true;
          fieldErrors.message.passwordPattern = `${name} must contain at least one uppercase letter, one lowercase letter, one number and one special character`;
        }
      }

      if (
        rule.emailPattern &&
        form[field] &&
        !rule.emailPattern.test(form[field]?.toString() ?? '')
      ) {
        fieldErrors.dirty = true;
        fieldErrors.message.emailPattern = 'Invalid email';
      }

      if (
        rule.urlPattern &&
        form[field] &&
        !rule.urlPattern.test(form[field]?.toString() ?? '')
      ) {
        fieldErrors.dirty = true;
        fieldErrors.message.urlPattern = 'Invalid URL';
      }

      if (
        rule.urlHttpsPattern &&
        form[field] &&
        !rule.urlHttpsPattern.test(form[field]?.toString() ?? '')
      ) {
        fieldErrors.dirty = true;
        fieldErrors.message.urlHttpsPattern = 'URL must start with https://';
      }

      if (rule.integer && !integerPattern.test(form[field]?.toString() ?? '')) {
        fieldErrors.dirty = true;
        fieldErrors.message.integer = `${name} must be an integer`;
      }

      if (rule.text && !textPattern.test(form[field]?.toString() ?? '')) {
        fieldErrors.dirty = true;
        fieldErrors.message.text = `${name} must contain only letters`;
      }
    }

    return fieldErrors;
  };

  const validate = (): { dirty: boolean; errors: ErrorMessages<T> } => {
    const currentErrors: ErrorMessages<T> = setInitialErrors();

    Object.keys(rules).forEach(key => {
      const field = key as keyof T;

      const fieldErrors = validateRule(key as keyof T);

      currentErrors[field] = fieldErrors;

      setErrors((prevErrors: ErrorMessages<T>) => ({
        ...prevErrors,
        [field]: fieldErrors,
      }));
    });

    const currentDirty = Object.keys(currentErrors).some(
      key => currentErrors[key as keyof T].dirty,
    );

    setDirty(currentDirty);

    return { dirty: currentDirty, errors: currentErrors };
  };

  const combineErros = (errors: Record<string, string>): string[] => {
    return Object.values(errors);
  };

  const touch = () => {
    return validate().dirty;
  };

  return { touch, dirty, errors, combineErros };
}

export default useValidate;
