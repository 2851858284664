import React, { createContext, useContext, useState, ReactNode } from 'react';

export type NotifyType = {
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
};
type UpdateStateType = (newState: NotifyType | null) => void;

const NotifyContext = createContext<
  { notify: NotifyType | null; setNotify: UpdateStateType } | undefined
>(undefined);

///NotifyProvider
export const NotifyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [notify, setState] = useState<NotifyType | null>(null);

  const setNotify: UpdateStateType = notify => {
    setState(notify);
  };

  return (
    <NotifyContext.Provider value={{ notify, setNotify }}>
      {children}
    </NotifyContext.Provider>
  );
};

export const useNotify = () => {
  const context = useContext(NotifyContext);
  if (!context) {
    throw new Error('useNotify must be used within a AuthProvider');
  }
  return context;
};
