import { Box, Button, Divider, SxProps, Typography } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { style } from './form.style';

type Props = {
  title?: string;
  loading: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
};

function CreateForm(props: Props) {
  const { title, loading, children, sx, onCancel, onSubmit } = props;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    onSubmit(event);
  };

  return (
    <Box sx={{ ...style, ...sx }}>
      <Typography variant="h4">{title}</Typography>

      <Divider sx={{ marginY: 3 }} />

      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={2}>
          {children}
        </Box>

        <Divider sx={{ marginY: 3 }} />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          columnGap="20px"
        >
          <Button
            variant="contained"
            color="error"
            disabled={loading}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={loading}
            loading={loading}
            color="success"
            variant="contained"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateForm;
