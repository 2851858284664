import { Box, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import TableElements from '../../../shared/components/StyledTableCell';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import { WebsiteItemType } from '@/shared/api/websites/websites.api.types';
import { Tid } from '@/shared/types/promises.types';

type Props = WebsiteItemType & {
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
  onRefresh: (id: Tid, url: string) => void;
};

export default function CategoryItem(props: Props) {
  const { id, crawler_status, url, onEdit, onDelete, onRefresh } = props;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleRefresh = () => {
    // if (crawler_status === 'done') {
    //   return;
    // }

    // if (crawler_status === 'waiting') {
    //   return;
    // }

    // if (crawler_status === 'processing') {
    //   return;
    // }

    if (onRefresh) {
      onRefresh(id, url);
    }
  };

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell align="center">
        {url}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell component="th" scope="row">
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              display: 'flex',

              mr: 1,
            }}
          >
            {crawler_status === 'done' ? (
              <DoneIcon
                color="success"
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            ) : crawler_status === 'terminated' ? (
              <ThumbDownAltIcon
                color="error"
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            ) : crawler_status === 'waiting' ? (
              <HourglassEmptyIcon
                color="warning"
                sx={{
                  width: '20px',
                  height: '20px',
                  margin: 'auto',
                }}
              />
            ) : crawler_status === 'processing' ? (
              <RunningWithErrorsIcon
                color="info"
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            ) : null}
          </Box>
          <Typography
            color={
              crawler_status === 'done'
                ? '#66bb6a'
                : crawler_status === 'waiting'
                  ? '#ffa726'
                  : crawler_status === 'processing'
                    ? '#29b6f6'
                    : '#f44336'
            }
          >
            {crawler_status}
          </Typography>
        </Box>
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        <IconButton onClick={handleEdit}>
          <VisibilityIcon color="success" />
        </IconButton>

        <IconButton onClick={handleRemove}>
          <DeleteIcon color="error" />
        </IconButton>

        <IconButton
          onClick={handleRefresh}
          sx={{
            ml: 1,
          }}
        >
          <CachedIcon color="action" />
        </IconButton>
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
