import { TUserItem } from '../types';
import { IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import TableElements from '@/shared/components/StyledTableCell';
import { Tid } from '@/shared/types/promises.types';
import { useState } from 'react';
import { useAuthentication } from '@/shared/context/authContext';
import roles from '../roles';
type Props = TUserItem & {
  onDelete?: (id: Tid) => void;
  onEdit: (id: Tid) => void;
};

export default function UserItem(props: Props) {
  const { id, email, first_name, last_name, role, status, onEdit, onDelete } =
    props;

  const { user } = useAuthentication();

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleEdit = () => {
    onEdit(id);
  };

  const [isSuperAdmin] = useState<boolean>(
    user?.role === 'superadmin' || user?.role === 'Super Admin',
  );

  const [isAdmin] = useState<boolean>(
    user?.role === 'admin' || user?.role === 'Admin',
  );

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell component="th" scope="row">
        {first_name + ' ' + last_name}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        <Typography
          title={email}
          textAlign="center"
          sx={{
            maxWidth: '150px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {email}
        </Typography>
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {roles.find(r => r.value === role)?.name || 'Super Admin'}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {status}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {isSuperAdmin ? (
          <IconButton onClick={handleEdit}>
            <EditIcon color="primary" />
          </IconButton>
        ) : isAdmin ? (
          <IconButton
            onClick={handleEdit}
            disabled={role === 'admin' || role === 'superadmin'}
          >
            <EditIcon
              color={
                role === 'admin' || role === 'superadmin'
                  ? 'disabled'
                  : 'primary'
              }
            />
          </IconButton>
        ) : (
          <IconButton onClick={handleEdit} disabled>
            <EditIcon color={'disabled'} />
          </IconButton>
        )}

        {isSuperAdmin ? (
          <IconButton onClick={handleRemove} disabled={status === 'deleted'}>
            <DeleteIcon color={status === 'deleted' ? 'disabled' : 'error'} />
          </IconButton>
        ) : isAdmin ? (
          <IconButton
            onClick={handleRemove}
            disabled={
              status === 'deleted' || role === 'admin' || role === 'superadmin'
            }
          >
            <DeleteIcon
              color={
                status === 'deleted' ||
                role === 'admin' ||
                role === 'superadmin'
                  ? 'disabled'
                  : 'error'
              }
            />
          </IconButton>
        ) : (
          <IconButton onClick={handleRemove} disabled>
            <DeleteIcon color={'disabled'} />
          </IconButton>
        )}
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
