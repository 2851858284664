import { TRoute } from '../../router/router.type';
import Websites from './websites.view';

const routes: TRoute[] = [
  {
    path: '/websites',
    name: 'websites',
    title: 'Websites',
    component: function () {
      return <Websites />;
    },
    exact: true,
  },
];

export default routes;
