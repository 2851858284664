import { useEffect, useState } from 'react';
import UserHistoryApi from './api/user-history.api';
import { Helmet } from 'react-helmet';
import ContentLayout from '@/shared/layouts/ContentLayout';
import { useNotify } from '@/shared/context/notifyContext';
import {
  GroupUserHistoryItemModel,
  // PaginationParamsType,
  UserHistoryGetParamsType,
} from './users-history.types';

import UserHistoryList from './components/UsersHistoryList';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import SearchBlock from '@/shared/components/SearchBlock';

function UserHistory() {
  const { setNotify } = useNotify();

  const [pages, setPages] = useState<number>(0);
  // const [paginationParams, setPaginationParams] =
  //   useState<PaginationParamsType>({
  //     limit: 10,
  //     offset: 0,
  //     total: 0,
  //   });

  const [queries, setQueries] = useQueryParams<UserHistoryGetParamsType>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [usersHistoryList, setUsersHistoryList] = useState<
    GroupUserHistoryItemModel[]
  >([]);

  const fetchUsersHistory = async () => {
    setLoading(true);

    try {
      const { data } = await UserHistoryApi.fetchGroupedUsersHistory({
        ...queries,
        group_by: 'true',
      });

      const { result, pagination } = data;

      const { limit, total } = pagination;

      setUsersHistoryList(result);
      // setPaginationParams(pagination);

      const pages = Math.ceil(total / limit);

      setPages(pages);
    } catch (error) {
      setNotify({
        message: 'Error while fetching user histroy',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (offset: number) => {
    setQueries({ ...queries, offset });
  };

  const onSearchHistory = (query: string) => {
    setQueries({ ...queries, query, offset: 1 });
  };

  useEffect(() => {
    fetchUsersHistory();
  }, [queries]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User History | INKHUB Admin Page</title>
      </Helmet>

      <ContentLayout title={<>User History</>} loading={loading}>
        <SearchBlock
          value={queries.query || ''}
          placeholder="Search user by name/email"
          onInput={onSearchHistory}
          sx={{ marginBottom: 4, maxWidth: 400 }}
        />
        <UserHistoryList
          usersHistory={usersHistoryList}
          loading={loading}
          pages={pages}
          params={queries}
          onChangePage={handleChangePage}
        />
      </ContentLayout>
    </>
  );
}

export default UserHistory;
