import { TRoute } from '@/router/router.type';
import UsersHistory from './users-history.view';
import UserHistory from './features/user-history.view';

const routes: TRoute[] = [
  {
    path: '/users-history',
    name: 'users-history',
    title: 'Users History',
    component: function () {
      return <UsersHistory />;
    },
    exact: true,
  },

  {
    path: '/users-history/:id',
    name: 'user-history',
    title: 'User History',
    component: function () {
      return <UserHistory />;
    },
    exact: true,
  },
];

export default routes;
