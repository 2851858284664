import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { useNotify } from '@/shared/context/notifyContext';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';

import ResourcesShareApi from '@/shared/api/resources/resources.api';
import UsersHistoryApi from '../api/user-history.api';

import {
  // PaginationParamsType,
  UserDataResponseModelType,
  UserHistoryGetParamsType,
} from '../users-history.types';
import { UserDownloadActionsItemType } from './user-history.types.d';

import UserInfo from './components/UserInfo';
import UserActionsList from './components/UserActionsList';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import SearchBlock from '@/shared/components/SearchBlock';

function UserHistoryContainer() {
  const { id } = useParams();
  const { setNotify } = useNotify();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<
    (UserDataResponseModelType & { name: string }) | null
  >(null);
  const [resourcesIDs, setResourcesIDs] = React.useState<number[]>([]);
  const [actions, setActions] = React.useState<UserDownloadActionsItemType[]>(
    [],
  );

  const [pages, setPages] = React.useState<number>(0);
  // const [_, setPaginationParams] =
  //   React.useState<PaginationParamsType>({
  //     limit: 10,
  //     offset: 0,
  //     total: 0,
  //   });
  const [queries, setQueries] = useQueryParams<UserHistoryGetParamsType>({});

  const fetchUserHistoryById = async () => {
    try {
      const { data } = await UsersHistoryApi.fetchUsersHistory({
        ...queries,
        email: id,
      });

      const { result, pagination, user_data } = data;
      const { limit, total } = pagination;

      const ids = result.map(item => item.meta_data.resource_id);

      const uniqueIds = [...new Set(ids)].filter(Boolean);
      if (result.length) {
        setUser({
          ...user_data,
          name: `${result[0].meta_data.name} ${result[0].meta_data.surname}`,
        });
      }

      const resultsActions: UserDownloadActionsItemType[] = result.map(item => {
        return {
          actions: item.action,
          resource_id: item.meta_data.resource_id,
          resource: null,
          timestamp: item.meta_data.action_time * 1000,
          role: item.meta_data.user_role,
          company_name: item.meta_data.company_name,
          company_url: item.meta_data.company_url,
        };
      });

      setActions(resultsActions);

      setResourcesIDs(uniqueIds);

      // setPaginationParams(pagination);
      const pages = Math.ceil(total / limit);

      setPages(pages);
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    }
  };

  const fetchResources = async () => {
    setLoading(true);

    try {
      const response = await Promise.allSettled(
        resourcesIDs.map(id => ResourcesShareApi.fetchResourceById(id)),
      );

      const data = response
        .map(item => (item.status === 'fulfilled' ? item.value.data : null))
        .filter(Boolean);

      const results = actions.map(action => {
        if (action.actions === 'CLAIM_PROFILE_REQUEST') {
          return {
            ...action,
          };
        }

        const actionResource = action as UserDownloadActionsItemType;

        const resource =
          data.find(item => item?.id === actionResource.resource_id) || null;

        return {
          ...action,
          resource,
        };
      });

      setActions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (offset: number) => {
    setQueries({ ...queries, offset });
  };

  const onSearchResource = (query: string) => {
    setQueries({ ...queries, query, offset: 0 });
  };

  useEffect(() => {
    fetchUserHistoryById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, queries]);

  useEffect(() => {
    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesIDs]);

  return (
    <Box>
      <Link to={'/users-history'}>
        <Typography
          variant="h6"
          component="p"
          gutterBottom
          display={'flex'}
          alignItems={'center'}
        >
          <ChevronLeft />
          Back to users history
        </Typography>
      </Link>

      {user && <UserInfo {...user} />}

      <Box marginTop={4}>
        <SearchBlock
          value={queries.query || ''}
          placeholder="Search resource by name"
          onInput={onSearchResource}
          sx={{ marginBottom: 4, maxWidth: 400 }}
        />

        {actions.length ? (
          <UserActionsList
            history={actions}
            pages={pages}
            loading={loading}
            params={queries}
            onChangePage={handleChangePage}
          />
        ) : (
          <Box>
            <Typography variant="h6" gutterBottom>
              No history found
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default UserHistoryContainer;
