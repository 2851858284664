import { Box, Button, Divider, Typography } from '@mui/material';
import { style } from './form.style';
import { LoadingButton } from '@mui/lab';

type Props = {
  title: string;
  message: string;
  loading: boolean;
  onRemove: () => void;
  onCancel: () => void;
};

function RemoveForm(props: Props) {
  const { title, message, loading, onRemove, onCancel } = props;

  return (
    <Box sx={style}>
      <Typography variant="h4">{title}</Typography>

      <Divider sx={{ marginY: 3 }} />

      <Typography variant="body1">{message}</Typography>

      <Divider sx={{ marginY: 3 }} />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        columnGap="20px"
      >
        <Button
          variant="contained"
          color="success"
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={loading}
          loading={loading}
          color="error"
          variant="contained"
          onClick={onRemove}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default RemoveForm;
