import { Typography } from '@mui/material';

const combineErros = (errors: Record<string, string>): string => {
  return Object.values(errors)[0];
};

const mappingErrors = (err: Record<string, string>) => {
  const error = combineErros(err);

  return (
    <Typography
      variant="subtitle2"
      display="block"
      marginTop="4px"
      lineHeight={1.2}
      color="error"
      sx={{
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      }}
    >
      {error}
    </Typography>
  );
};

export default mappingErrors;
