import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Cookie from 'js-cookie';

// const prepareParams = (params: any) => {
//   return Object.keys(params).reduce((acc: { [key: string]: any }, key) => {
//     const value = params[key];

//     if (value) {
//       acc[key] = value;
//     }
//     return acc;
//   }, {});
// };
class HTTPClient {
  private baseURL: string;
  private 'X-Access-Token': string | undefined;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this['X-Access-Token'] = this.getTokenFromCookie();
  }

  private getTokenFromCookie(): string | undefined {
    return Cookie.get('XAccessToken');
  }

  public setToken(token: string): void {
    this['X-Access-Token'] = token;

    Cookie.set('XAccessToken', token);
  }

  public getToken(): string | undefined {
    return this['X-Access-Token'];
  }

  public clearToken(): void {
    this['X-Access-Token'] = undefined;

    Cookie.remove('XAccessToken');
  }

  private generateOptions(options?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      headers: {
        ...options?.headers,
        'Content-Type': 'application/json',
        'X-Access-Token': this['X-Access-Token'] || '',
      },
      ...options,
    };
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const configParams = this.generateOptions(config);
    try {
      const response: AxiosResponse<T> = await axios.get<T>(
        `${this.baseURL}${url}`,
        (config = configParams),
      );
      return response.data;
    } catch (error) {
      throw this.handleErrorResponse(error as AxiosError<unknown, any>);
    }
  }

  public async post<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    try {
      const settings = this.generateOptions(config);
      const response: AxiosResponse<R> = await axios.post<R>(
        `${this.baseURL}${url}`,
        data,
        settings,
      );
      return response.data;
    } catch (error) {
      throw this.handleErrorResponse(error as AxiosError<unknown, any>);
    }
  }

  public async postFile<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    try {
      const settings = this.generateOptions({
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Access-Token': this['X-Access-Token'] || '',
        },
        ...config,
      });

      const response: AxiosResponse<R> = await axios.post<R>(
        `${this.baseURL}${url}`,
        data,
        settings,
      );
      return response.data;
    } catch (error) {
      throw this.handleErrorResponse(error as AxiosError<unknown, any>);
    }
  }

  public async put<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    try {
      const settings = this.generateOptions(config);

      const response: AxiosResponse<R> = await axios.put<R>(
        `${this.baseURL}${url}`,
        data,
        settings,
      );
      return response.data;
    } catch (error) {
      throw this.handleErrorResponse(error as AxiosError<unknown, any>);
    }
  }

  public async patch<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    try {
      const settings = this.generateOptions(config);

      const response: AxiosResponse<R> = await axios.patch<R>(
        `${this.baseURL}${url}`,
        data,
        settings,
      );
      return response.data;
    } catch (error) {
      throw this.handleErrorResponse(error as AxiosError<unknown, any>);
    }
  }

  public async delete(url: string, config?: AxiosRequestConfig): Promise<void> {
    try {
      const settings = this.generateOptions(config);

      await axios.delete(`${this.baseURL}${url}`, settings);
    } catch (error) {
      throw this.handleErrorResponse(error as AxiosError<unknown, any>);
    }
  }

  private handleErrorResponse(error: AxiosError): Error {
    if (error.response) {
      return new Error(JSON.stringify(error.response));
    } else if (error.request) {
      return new Error('Request failed: no response received');
    } else {
      return new Error(`Request setup error: ${error.message}`);
    }
  }
}

const httpClient = new HTTPClient(
  process.env.REACT_APP_API_URL || ('https://api.dev.inkhub.com/api' as string),
);

export default httpClient;
