import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SelectChangeEvent } from '@mui/material';

import { TCompanyForm, TCompanyUpdateForm, OrderType } from './types';

import {
  TOnCreateItem,
  TOnRemoveItem,
  TOnEditItem,
} from '@/shared/types/methods.types';

import {
  CompanyItemType,
  GetParamsCompanyType,
} from '@/shared/api/compnaies/compnaies.api.types';

import CompaniesDataApi from './api/companies.api';
import WebsiteDataApi from '@/shared/api/websites/websites.api';
import { GetCategoriesItems } from '@/shared/api/categories/categories.api';

import CompaniesList from './components/CompaniesList';
import CompanyForm from './forms/company.form';
import CompaniesFilters from './components/CompaniesFilters';

import ContentLayout from '@/shared/layouts/ContentLayout';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import { useNotify } from '@/shared/context/notifyContext';

import {
  TCategoryItem,
  TCategoriesGetParams,
} from '@/shared/api/categories/categories.api.types';

import {
  PopularityFilterType,
  StatusesFilterType,
  TypesCountingFilterType,
  TypesFilterType,
} from '@/shared/types/general.types';
import {
  TGetWebsitesParams,
  WebsiteItemType,
} from '@/shared/api/websites/websites.api.types';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';

type onRemoveCompany = TOnRemoveItem;
type onCreateCompany = TOnCreateItem<TCompanyForm>;
type onEditCompany = TOnEditItem<TCompanyUpdateForm>;

function CompaniesContainer() {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<GetParamsCompanyType>({});
  const [offset] = useState<string>('10');
  const [pages, setPages] = useState<number>(1);

  const [subCategoriesParams, setSubCategoriesParams] =
    useState<TCategoriesGetParams>({
      page: 1,
      pageCount: 30,
      parentId: -1,
      query: '',
    });

  const [websitesParams, setwebsitesParams] = useState<TGetWebsitesParams>({
    page: '1',
    pageCount: '30',
    query: '',
  });

  const [companies, setCompanies] = useState<CompanyItemType[]>([]);
  const [categories, setCategories] = useState<TCategoryItem[]>([]);
  const [subCategories, setSubCategories] = useState<TCategoryItem[]>([]);
  const [websites, setWebsites] = useState<WebsiteItemType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCompanyForm, setLoadingCompanyForm] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const [selectedCompany, setSelectedCompany] =
    useState<CompanyItemType | null>(null);

  const [selectedCategiries, setSelectedCategories] = useState<TCategoryItem[]>(
    [],
  );

  const onClearModalState = () => {
    setRemoveModal(false);
    setSelectedCompany(null);
    setOpenModal(false);
  };

  const onSetSelectedCompany = (id: string | number) => {
    setSelectedCompany(companies.find(company => company.id === id) || null);
  };

  const handleClose = () => {
    onClearModalState();
    setLoadingCompanyForm(false);
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedCompany(null);
  };

  const handleOpenRemoveModal = (id: string | number) => {
    setRemoveModal(true);
    setOpenModal(true);
    onSetSelectedCompany(id);
  };

  const handleOpenEditModal = (id: string | number) => {
    setOpenModal(true);
    onSetSelectedCompany(id);
  };

  const fetchCompanies = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);

      try {
        const { data, total_items } = await CompaniesDataApi.fetchCompaniesList(
          {
            ...queries,
            pageCount: offset,
          },
          signal,
        );

        const total = total_items ? total_items : 0;

        setPages(Math.ceil(total / +offset));

        setCompanies(data);

        setEmptyRequests(prev => prev + 1);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [queries],
  );

  const fetchSubCategories = async () => {
    try {
      const { data } = await GetCategoriesItems(subCategoriesParams);

      setSubCategories([...subCategories, ...data]);
    } catch (error) {
      setNotify({ message: 'Error while fetching categories', type: 'error' });
    }
  };
  const fetchCategories = async () => {
    try {
      const { data } = await GetCategoriesItems({
        pageCount: 1000,
        parentId: 0,
      });

      setCategories([...categories, ...data]);
    } catch (error) {
      setNotify({ message: 'Error while fetching categories', type: 'error' });
    }
  };

  const fetchWebsites = async () => {
    try {
      const { data } = await WebsiteDataApi.getWebsitesList(websitesParams);

      setWebsites(data);
    } catch (error) {
      setNotify({ message: 'Error while fetching websites', type: 'error' });
    }
  };

  const onSearchCategories = (search: string) => {
    setSubCategories([]);

    setSubCategoriesParams({ ...subCategoriesParams, query: search });
  };

  const onSearchWebsites = (search: string) => {
    setWebsites([]);

    setwebsitesParams({ ...websitesParams, query: search });
  };

  const onIntersectCategories = () => {
    setSubCategoriesParams({
      ...subCategoriesParams,
      page:
        subCategoriesParams.page !== undefined
          ? +subCategoriesParams.page + 1
          : 1,
    });
  };

  const onSortCompanies = (sort: string) => {
    const newSort: OrderType = queries.order_by?.includes(sort)
      ? queries.order_by.includes('-')
        ? (sort as OrderType)
        : (`-${sort}` as OrderType)
      : (sort as OrderType);

    setQueries({ ...queries, order_by: newSort, page: '1' });
  };

  const onSearchCompanies = (query: string) => {
    if (query.length === 0 && epmtyRequests !== 1) {
      setQueries({ ...queries, query: undefined, page: '1' });
      return;
    }

    if (query.length >= 3) {
      setQueries({ ...queries, query, page: '1' });
      return;
    }
  };

  const onSelectedCompanyType = (
    companyType: SelectChangeEvent<TypesFilterType['value']>,
  ) => {
    if (!companyType.target.value || companyType.target.value === 'all') {
      setQueries({ ...queries, companyType: undefined, page: '1' });
    } else {
      setQueries({
        ...queries,
        companyType: companyType.target.value as TypesFilterType['value'],
        page: '1',
      });
    }
  };

  const onSelectedCompanyPopular = (
    companyPopular: SelectChangeEvent<PopularityFilterType['value']>,
  ) => {
    if (companyPopular.target.value === 'all') {
      setQueries({ ...queries, isPopular: undefined, page: '1' });
    } else {
      setQueries({
        ...queries,
        isPopular: companyPopular.target.value as 'true' | 'false',
        page: '1',
      });
    }
  };

  const onSelectedStatus = (
    status: SelectChangeEvent<StatusesFilterType['value']>,
  ) => {
    if (status.target.value === 'all') {
      setQueries({ ...queries, status: undefined, page: '1' });
    } else {
      setQueries({
        ...queries,
        status: status.target.value as StatusesFilterType['value'],
        page: '1',
      });
    }
  };

  const onSelectedCompanyWithCounting = (
    companyCounting: SelectChangeEvent<TypesCountingFilterType['value']>,
  ) => {
    if (companyCounting.target.value === 'all') {
      setQueries({ ...queries, withCounting: undefined, page: '1' });
    } else {
      setQueries({
        ...queries,
        withCounting: companyCounting.target.value as 'true' | 'false',
        page: '1',
      });
    }
  };

  const onSelectedCategories = (value: string) => {
    setQueries({ ...queries, categories: value || undefined, page: '1' });
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  const onRemoveCompany: onRemoveCompany = async () => {
    setLoadingCompanyForm(true);

    try {
      if (selectedCompany) {
        await CompaniesDataApi.deleteCompany(selectedCompany.id);

        fetchCompanies();

        setNotify({ message: 'Company removed successfully', type: 'success' });

        onClearModalState();
      }
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingCompanyForm(false);
    }
  };

  const onCreateCompany: onCreateCompany = async form => {
    setLoadingCompanyForm(true);

    try {
      const response = await CompaniesDataApi.createCompany(form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      fetchCompanies();

      setNotify({ message: 'Company created successfully', type: 'success' });

      onClearModalState();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingCompanyForm(false);
    }
  };

  const onEditCompany: onEditCompany = async (id, form) => {
    setLoadingCompanyForm(true);

    try {
      const response = await CompaniesDataApi.updateCompany(id, form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      const { data } = response;

      setCompanies(companies.map(item => (item.id === data.id ? data : item)));

      setNotify({ message: 'Company updated successfully', type: 'success' });

      onClearModalState();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingCompanyForm(false);
    }
  };

  const onRetrieveCompanyInfo = async (name: string) => {
    try {
      const response = await CompaniesDataApi.retrieveCompanyInfo({
        company_name: name,
      });

      console.log('response: ', response);
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchCompanies(controller.signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  useEffect(() => {
    fetchSubCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoriesParams]);

  useEffect(() => {
    fetchWebsites();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websitesParams]);

  useEffect(() => {
    fetchCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const categoriesValue = queries.categories?.split(',');
    if (categoriesValue) {
      Promise.all(
        categoriesValue.map(async value => {
          const response = await GetCategoriesItems({
            page: 1,
            pageCount: 30,
            query: value,
          });

          return response.data;
        }),
      ).then(data => {
        setSelectedCategories(data.flat());
      });
    }
  }, []);

  const memoizedCompanies = useMemo(() => companies, [companies]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>INKHUB Admin Pange | Companies</title>
      </Helmet>
      <ContentLayout
        title="Companies"
        buttonTitle="Create new Company"
        removeTitleModal="Remove Company"
        removeTitleMessage="Are you sure you want to remove this company?"
        loading={loading}
        formLoading={loadingCompanyForm}
        openModal={openModal}
        isRemoveModal={removeModal}
        selectedItem={selectedCompany}
        onRemoveItem={onRemoveCompany}
        onCloseModal={handleClose}
        onCreate={handleOpenCreateModal}
        form={
          <CompanyForm
            loading={loadingCompanyForm}
            company={selectedCompany}
            categories={categories}
            subcategories={subCategories}
            websites={websites}
            onCancel={handleClose}
            onCreate={onCreateCompany}
            onUpdate={onEditCompany}
            onSearchCategories={onSearchCategories}
            onIntersectCategories={onIntersectCategories}
            onSearchWebsites={onSearchWebsites}
          />
        }
      >
        <CompaniesFilters
          queries={queries}
          categories={categories}
          subcategories={subCategories}
          selectedCategiries={selectedCategiries}
          onSearchCompanies={onSearchCompanies}
          onSelectedCompanyType={onSelectedCompanyType}
          onSelectedCompanyPopular={onSelectedCompanyPopular}
          onSelectedCompanyWithCounting={onSelectedCompanyWithCounting}
          onSearchCategories={onSearchCategories}
          onSelectedCategories={onSelectedCategories}
          onSelectedStatus={onSelectedStatus}
        />

        <CompaniesList
          companies={memoizedCompanies}
          params={queries}
          pages={pages}
          loading={loading}
          onDelete={handleOpenRemoveModal}
          onEdit={handleOpenEditModal}
          onSort={onSortCompanies}
          onChangePage={onChangePage}
          onRetrieve={onRetrieveCompanyInfo}
        />
      </ContentLayout>
    </>
  );
}

export default CompaniesContainer;
