import { styled, SxProps } from '@mui/material/styles';
import TableCell, {
  TableCellBaseProps,
  tableCellClasses,
} from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ElementType } from 'react';
import { Box, Typography } from '@mui/material';

const StyledTableCellStyles = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
type PropsTableCell = {
  children?: React.ReactNode;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  scope?: string;
  component?: ElementType<TableCellBaseProps>;
  sortable?: boolean;
  sortDirection?: 'asc' | 'desc' | false;
  sx?: SxProps;
  title?: string;
  textProps?: SxProps;
  onClick?: () => void;
};

export const StyledTableCell = (props: PropsTableCell) => (
  <StyledTableCellStyles
    align={props.align}
    component={props.component || 'th'}
    scope={props.scope}
    sx={{
      ...(props.sortable ? { cursor: 'pointer' } : {}),
      ...props.sx,
    }}
    title={props.title}
    onClick={() => props.onClick && props.onClick()}
  >
    {props.sortable ? (
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.children}

        {props.sortDirection && (
          <KeyboardArrowDownIcon
            sx={{
              marginLeft: 1,
              transform: `rotate(${
                props.sortDirection === 'asc' ? '0deg' : '180deg'
              })`,
            }}
          />
        )}
      </Box>
    ) : (
      <Typography variant="body2" sx={{ ...props.textProps }}>
        {props.children}
      </Typography>
    )}
  </StyledTableCellStyles>
);
