import { TRouter } from './router.type';

//layouts
import AuthLayout from '@/layouts/auth.layout';
import MainLayout from '@/layouts/main.layout';

//pages
import Categories from '@/entities/categories/categories.route';
import Resources from '@/entities/resources/resources.route';
import Companies from '@/entities/companies/companies.route';
import Websites from '@/entities/websites/websites.route';
import Users from '@/entities/users/users.route';
import Types from '@/entities/types/types.route';
import Notifications from '@/entities/notifications/notifications.route';
import Login from '@/entities/login/login.route';
import Subcategories from '@/entities/subcategories/subcategories.route';
import UsersHistory from '@/entities/users-history/users-history.route';

const routes: TRouter[] = [
  {
    layout: AuthLayout,
    isProtected: false,
    routes: [...Login],
  },

  {
    layout: MainLayout,
    isProtected: true,
    routes: [
      ...Categories,
      ...Resources,
      ...Companies,
      ...Websites,
      ...Users,
      ...Types,
      ...Notifications,
      ...Subcategories,
      ...UsersHistory,
    ],
  },
];

export default routes;
