import moment from 'moment';

export default function formatTimeDifference(created_at: string): string {
  const currentTime: Date = new Date();
  const createdAt: Date = new Date(created_at);
  const differenceInSeconds: number = Math.floor(
    (currentTime.getTime() - createdAt.getTime()) / 1000,
  );

  if (differenceInSeconds < 60) {
    return 'now';
  } else if (differenceInSeconds < 3600) {
    const minutes: number = Math.floor(differenceInSeconds / 60);
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
  } else if (differenceInSeconds < 86400) {
    const hours: number = Math.floor(differenceInSeconds / 3600);
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
  } else if (differenceInSeconds < 604800) {
    const days: number = Math.floor(differenceInSeconds / 86400);
    return `${days} ${days > 1 ? 'days' : 'day'} ago`;
  } else {
    return moment(created_at).format('YYYY-MM-DD');
  }
}
