import { CircularProgress } from '@mui/material';
import TableElements from '../StyledTableCell';

function LoadingTable() {
  return (
    <TableElements.StyledTableRow
      sx={{
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <TableElements.StyledTableCell component="th" scope="row">
        <CircularProgress />
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}

export default LoadingTable;
