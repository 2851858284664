import { Box, IconButton, SxProps } from '@mui/material';
import DebounceInput from '../DebounceInput';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

type Props = {
  value: string;
  onInput: (value: string) => void;
  placeholder: string;
  sx?: SxProps;
};

function SearchBlock(props: Props) {
  const { value, onInput, placeholder, sx } = props;

  const [state, setState] = React.useState(value as string);

  const handleInput = (value: string) => {
    onInput(value);
  };

  return (
    <Box display="flex" padding={'1rem 0'} borderRadius={2} sx={sx}>
      <DebounceInput
        value={state}
        placeholder={placeholder}
        debounceTimeout={300}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
          setState(e.target.value)
        }
        handleDebounce={handleInput}
        sx={{ flex: 1 }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Box>
  );
}

export default SearchBlock;
