import { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import httpClient from '@/core/client/http.client';
import { useAuthentication } from '@/shared/context/authContext';
import { useNotify } from '@/shared/context/notifyContext';
import UserApi from '@/shared/api/user/user.api';

import LoginDataApi from './api/login.api';
import LoginForm from './forms/login.form';

const LoginContainer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error] = useState<string | null>(null);
  const { setAuthenticated, setUser } = useAuthentication();
  const { setNotify } = useNotify();

  const fetchUser = async () => {
    try {
      const response = await UserApi.fetchCurrentUser();

      setAuthenticated(!!response.data);
      setUser(response.data);
    } catch (error) {
      httpClient.clearToken();
      setAuthenticated(false);
    }
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);
    try {
      const response = await LoginDataApi.login(data);
      const token = response.data.access_token;

      setAuthenticated(!!token);

      httpClient.setToken(token);

      navigate('/');

      setNotify({ message: 'Successfully logged in', type: 'success' });

      fetchUser();
    } catch (error) {
      setNotify({ message: 'Invalid login or password', type: 'error' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <LoginForm isLoading={loading} error={error} onSubmit={onSubmit} />
    </Box>
  );
};

export default LoginContainer;
