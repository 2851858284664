import { TTypeItem } from '@/shared/api/types/types.api.types';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableElements from '@/shared/components/StyledTableCell';
import { Tid } from '@/shared/types/promises.types';

type Props = TTypeItem & {
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
};

export default function TypeItem(props: Props) {
  const { id, name, status, created_at, onEdit, onDelete } = props;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell component="th" scope="row">
        {name}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {status}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {created_at}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        <IconButton onClick={handleEdit}>
          <EditIcon color="success" />
        </IconButton>

        <IconButton onClick={handleRemove}>
          <DeleteIcon color="error" />
        </IconButton>
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
