import { Box, Button, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ModeSwitcher from '../../components/ModeSwitcher';
import httpClient from '@/core/client/http.client';
import { useAuthentication } from '@/shared/context/authContext';
import { useNavigate } from 'react-router-dom';

type Props = {
  open: boolean;
  handleChangeMode?: () => void;
  handleToggleOpen: () => void;
};

export function Header(props: Props) {
  const { handleToggleOpen, open, handleChangeMode } = props;

  const navigate = useNavigate();

  const { setAuthenticated, setUser } = useAuthentication();

  const handleLogout = async () => {
    await httpClient.get('/user/logout');
    httpClient.clearToken();
    setAuthenticated(false);
    setUser(null);
    navigate('/login');
  };

  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleToggleOpen}
        sx={{
          marginRight: 5,
          ...(open && { display: 'none' }),
        }}
      >
        <MenuIcon color={'action'} />
      </IconButton>
      <Box marginLeft="auto">
        <ModeSwitcher onChange={handleChangeMode} />

        <Button type="button" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Toolbar>
  );
}
