import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type QueryParams<T> = Record<
  keyof T,
  string | number | boolean | null | undefined
>;

function serializeFormQuery<T>(params: QueryParams<T>): string {
  return Object.entries(params)
    .filter(
      ([_, value]) =>
        value != null &&
        value !== '' &&
        value !== undefined &&
        value !== false &&
        value !== 0,
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )
    .join('&');
}

type SetQueryParams<T> = (params: T) => void;
type GetQueryParams<T> = () => T;

function useQueryParams<T>(initialParams: T): [T, SetQueryParams<T>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams: SetQueryParams<T> = params => {
    const queryString = serializeFormQuery(params as QueryParams<T>);

    setSearchParams(new URLSearchParams(queryString));
  };

  const getQueryParams: GetQueryParams<T> = () => {
    const queryParams: T = initialParams as T;

    for (const [key, value] of searchParams.entries()) {
      queryParams[key as keyof T] = value as any;
    }

    return queryParams;
  };

  const [params, setNewParams] = useState<T>(getQueryParams() as T);

  const setParams = (newParams: T) => {
    setNewParams(newParams);
    setQueryParams(newParams);
  };

  return [params, setParams];
}

export default useQueryParams;
