import moment from 'moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { UserDownloadActionsItemType } from '../user-history.types';

type Props = UserDownloadActionsItemType;

export default function UserActionsItem(props: Props) {
  const { actions, resource, role, timestamp, company_name, company_url } =
    props;

  if (actions === 'CLAIM_PROFILE_REQUEST') {
    return (
      <Card
        sx={{
          position: 'relative',
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {actions}
          </Typography>

          <Typography variant="body2" color="text.secondary" mt={2}>
            Role in INKHUB: {role || '-'}
            <br />
            <br />
            Company Name: {company_name}
            <br />
            <br />
            Company URL: {company_url || '-'}
            <br />
            <br />
            Action Time: {moment(timestamp).format('DD/MM/YYYY : HH:mm')}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <CardMedia
        sx={{ height: 140, backgroundSize: 'contain' }}
        image={resource?.image_path}
        title={resource?.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {actions}
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={2}>
          Role in INKHUB: {role || '-'}
          <br />
          <br />
          Resource Name: {resource?.name}
          <br />
          <br />
          Resource Type: {resource?.resource_type || '-'}
          <br />
          <br />
          Action Time: {moment(timestamp).format('DD/MM/YYYY : HH:mm')}
        </Typography>
      </CardContent>
    </Card>
  );
}
