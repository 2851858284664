import {
  TypesFilterType,
  PopularityFilterType,
  TypesCountingFilterType,
  StatusesFilterType,
} from '../types/general.types';

export const typesFilter: TypesFilterType[] = [
  {
    id: 0,
    name: 'All',
    value: 'all',
  },
  {
    id: 1,
    name: 'Services',
    value: 'services',
  },
  {
    id: 2,
    name: 'Software',
    value: 'software',
  },
];

export const popularityFilter: PopularityFilterType[] = [
  {
    id: 0,
    name: 'All',
    value: 'all',
  },
  {
    id: 1,
    name: 'Popular',
    value: 'true',
  },
  {
    id: 2,
    name: 'Not Popular',
    value: 'false',
  },
];

export const withCountFilter: TypesCountingFilterType[] = [
  {
    id: 0,
    name: 'All',
    value: 'all',
  },
  {
    id: 1,
    name: 'With count',
    value: 'true',
  },
  {
    id: 2,
    name: 'Without count',
    value: 'false',
  },
];

export const statusesFilter: StatusesFilterType[] = [
  {
    id: 0,
    name: 'All',
    value: 'all',
  },
  {
    id: 1,
    name: 'Claimed',
    value: 'claimed',
  },
  {
    id: 2,
    name: 'Unclaimed',
    value: 'unclaimed',
  },
];
