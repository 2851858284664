import { useNavigate } from 'react-router-dom';

import TableElements from '@/shared/components/StyledTableCell';
import formatTimeDifference from '@/shared/helpers/format-date.helper';

import { GroupUserHistoryItemModel } from '../users-history.types';

type Props = GroupUserHistoryItemModel & {};

export default function UserHistoryItem(props: Props) {
  const { email, last_action, total, full_name, role, company_name } = props;

  const navigate = useNavigate();

  const handleRoute = (email: string) => {
    navigate(`/users-history/${email}`);
  };

  return (
    <TableElements.StyledTableRow
      hover
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1) !important',

          transition: '0.15s',
          cursor: 'pointer',
        },
      }}
      onClick={() => handleRoute(email)}
    >
      <TableElements.StyledTableCell>{email}</TableElements.StyledTableCell>

      <TableElements.StyledTableCell>{full_name}</TableElements.StyledTableCell>

      <TableElements.StyledTableCell>
        {company_name || 'N/A'}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell>{role}</TableElements.StyledTableCell>

      <TableElements.StyledTableCell>
        {formatTimeDifference(last_action)}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell>{total}</TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
