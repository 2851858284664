import { SxProps } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

type Props = {
  pages: number;
  page: number;
  shape?: 'rounded' | 'circular' | undefined;
  variant?: 'text' | 'outlined';
  defaultPage?: number;
  siblingCount?: number;
  boundaryCount?: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  sx?: SxProps;
};

export default function Paginations(props: Props) {
  const {
    pages,
    page,
    shape = 'rounded',
    variant = 'outlined',
    defaultPage = 6,
    siblingCount = 0,
    boundaryCount = 2,
    onChange,
    sx,
  } = props;

  if (pages <= 1) {
    return null;
  }

  return (
    <Stack spacing={2} marginTop={4} sx={sx}>
      <Pagination
        count={pages}
        page={page}
        variant={variant}
        shape={shape}
        defaultPage={defaultPage}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        onChange={onChange}
      />
    </Stack>
  );
}
