import { Box } from '@mui/material';

import Pagination from '@/shared/components/Pagination';

import UserActionsItem from './UserActionsItem';

import { UserHistoryGetParamsType } from '../../users-history.types';
import { UserDownloadActionsItemType } from '../user-history.types';

type Props = {
  history: UserDownloadActionsItemType[];
  params: UserHistoryGetParamsType;
  loading: boolean;
  pages: number;

  onChangePage: (offset: number) => void;
};

export default function UserActionsList(props: Props) {
  const { history, pages, params, onChangePage } = props;

  return (
    <>
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(auto-fill, minmax(360px, 1fr))'}
        columnGap={2}
        rowGap={2}
      >
        {history?.map((user, index) => (
          <UserActionsItem key={user.actions + index} {...user} />
        ))}
      </Box>
      {pages > 1 && (
        <Pagination
          pages={pages}
          page={params.offset || 1}
          onChange={(_, value) => onChangePage(value)}
        />
      )}
    </>
  );
}
