import React from 'react';
import { Box, Typography } from '@mui/material';
import { UserDataResponseModelType } from '../../users-history.types';

type Props = UserDataResponseModelType & { name: string };

const UserInfo = ({ company, email, role, user_id, full_name }: Props) => {
  return (
    <Box>
      <Typography variant="h1" gutterBottom>
        <b>{full_name}</b>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Role: <b>{role}</b>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Email: <b>{email}</b>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Company: <b>{company || 'N/A'}</b>
      </Typography>

      <Typography variant="body1" gutterBottom>
        User ID: <b>{user_id}</b>
      </Typography>
    </Box>
  );
};

export default UserInfo;
