import { TRoute } from '@/router/router.type';
import Types from './types.view';

const routes: TRoute[] = [
  {
    path: '/types',
    name: 'types',
    title: 'Types',
    component: function () {
      return <Types />;
    },
    exact: true,
  },
];

export default routes;
