import { TRoute } from '../../router/router.type';
import Resources from './resources.view';

const routes: TRoute[] = [
  {
    path: '/resources',
    name: 'resources',
    title: 'Resources',
    component: function () {
      return <Resources />;
    },
    exact: true,
  },
];

export default routes;
