import { TCategoryItem } from '@/shared/api/categories/categories.api.types.d';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableElements from '@/shared/components/StyledTableCell';
import { Tid } from '@/shared/types/promises.types';

type Props = TCategoryItem & {
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
};

export default function CategoryItem(props: Props) {
  const {
    id,
    name,
    category_type,
    status,
    image_svg_content,

    onEdit,
    onDelete,
  } = props;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell align="center">
        <Box
          dangerouslySetInnerHTML={{ __html: image_svg_content }}
          width={45}
          height={45}
          mx={'auto'}
          sx={{
            '& svg': {
              width: '100%',
              height: '100%',
            },
          }}
        />
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell component="th" scope="row">
        {name}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {category_type}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {status}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        <IconButton onClick={handleEdit}>
          <EditIcon color="success" />
        </IconButton>

        <IconButton onClick={handleRemove}>
          <DeleteIcon color="error" />
        </IconButton>
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
