import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';

import { useNotify } from '../../../../shared/context/notifyContext';
import { Box, CircularProgress } from '@mui/material';

import CompaniesDataApi from '../../api/companies.api';
import FormImageLoader from '../../../../shared/components/FormImageLoader/FormImageLoader';

type TGetCompamnyById = (id: string | number) => Promise<void>;

const CompanyContainer = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const { setNotify } = useNotify();

  const [company, setCompany] = useState<CompanyItemType | null>(null);

  const handleUpdateLogoCompany = (value: string) => {
    setCompany(prevValue => {
      if (!prevValue) {
        return null;
      }

      return {
        ...prevValue,
        image_path: value,
      };
    });
  };

  useEffect(() => {
    if (id) {
      const getCompanyById: TGetCompamnyById = async id => {
        setLoading(true);

        try {
          const response = (await CompaniesDataApi.getCompanyById(id)) || null;

          setCompany(response);
        } catch (error) {
          setNotify({
            message: 'Error fetching company',
            type: 'error',
          });
        } finally {
          setLoading(false);
        }
      };

      getCompanyById(id);
    }
  }, [id]);

  const memoizedCompany = useMemo(() => company, [company]);

  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          CompanyContainer {memoizedCompany?.name}
          <Box>
            <FormImageLoader
              value={company?.image_path || ''}
              onChange={handleUpdateLogoCompany}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CompanyContainer;
