import './styles.css';
import { useState } from 'react';

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { TCompanyForm, TCompanyUpdateForm } from '../types';
import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';

import useValidate, {
  Rules,
  emailPattern,
  urlPattern,
  urlHttpsPattern,
} from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';

import FormModal from '@/shared/components/Forms/FormModal';
import ImageLoader from '@/shared/components/FormImageLoader';
import DebounceInput from '@/shared/components/DebounceInput';
import { TCategoryItem } from '@/shared/api/categories/categories.api.types';
import { WebsiteItemType } from '@/shared/api/websites/websites.api.types';
import { useAuthentication } from '@/shared/context/authContext';

import { employees } from '../constants';

type Props = {
  company?: CompanyItemType | null | undefined;
  categories: TCategoryItem[];
  subcategories: TCategoryItem[];
  websites: WebsiteItemType[];
  loading: boolean;
  onCreate: (form: TCompanyForm) => void;
  onUpdate: (id: string | number, form: TCompanyUpdateForm) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
  onSearchCategories: (search: string) => void;
  onSearchWebsites: (search: string) => void;
  onIntersectCategories: () => void;
};

const CompanyForm = (props: Props) => {
  const {
    company,
    subcategories,
    categories,
    onCancel,
    onCreate,
    onUpdate,
    onSearchCategories,
    loading,
  } = props;

  const [isEdit] = useState<boolean>(!!company);
  const { user } = useAuthentication();
  const [isAdmin] = useState<boolean>(
    user?.role === 'admin' || user?.role === 'Admin',
  );
  const [isSuperAdmin] = useState<boolean>(
    user?.role === 'superadmin' || user?.role === 'Super Admin',
  );

  const [form, setForm] = useState<TCompanyForm>({
    name: company?.name || '',
    description: company?.description || '',
    status: company?.status || 'unclaimed',
    address: company?.address || '',
    company_email: company?.company_email || '',
    image_path: company?.image_path || '',
    phone_number: company?.phone_number || '',
    site_url: company?.site_url || '',
    categories:
      company?.categories.map(category => category.value).join(',') || '',

    company_size: company?.company_size || '',
    year_founded: company?.year_founded?.toString() || null,
    x_link: company?.x_link || '',
    linkedin_link: company?.linkedin_link || '',
  });

  const [selectedEmployeesSize, setSelectedEmployeesSize] = useState<string>(
    form.company_size || '',
  );

  const rules: Rules<TCompanyForm> = {
    name: {
      required: true,
    },
    description: {
      required: true,
    },

    status: {
      required: true,
    },

    address: {
      required: true,
    },

    company_email: {
      required: true,
      emailPattern,
    },

    phone_number: {
      required: true,
    },

    site_url: {
      required: {
        message: 'Website URL is required',
      },
      urlPattern,
      urlHttpsPattern,
    },

    categories: {
      required: {
        message: 'Categories are required',
      },
    },

    image_path: {
      required: true,
    },

    year_founded: {
      required: false,
    },

    x_link: {
      required: false,
      urlPattern,
    },

    linkedin_link: {
      required: false,
      urlPattern,
    },

    company_size: {
      required: false,
    },
  };

  const { touch, dirty, errors } = useValidate<TCompanyForm>(form, rules);

  const [dateError, setDateError] = useState<string>('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    if (loading || touch() || dateError) {
      return;
    }

    if (isEdit && company) {
      onUpdate(company.id, form);
    } else {
      onCreate(form);
    }
  };

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));

    if (dirty) {
      touch();
    }
  };

  const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      status: value as TCompanyForm['status'],
    }));
  };

  const onSelectCategories = (items: TCategoryItem[]) => {
    setForm(prevForm => ({
      ...prevForm,
      categories: items.map(item => item.value).join(','),
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectImage = (value: string) => {
    setForm(prevForm => ({
      ...prevForm,
      image_path: value,
    }));
  };

  const handleSelectedEmployeesSize = (event: SelectChangeEvent<string>) => {
    setSelectedEmployeesSize(event.target.value);

    setForm(prevForm => ({
      ...prevForm,
      company_size: event.target.value,
    }));
  };

  const handleSelectedYear = (date: Dayjs | null) => {
    setDateError('');
    const year = date?.format('YYYY');

    if (!year) {
      setForm(prevForm => ({
        ...prevForm,
        year_founded: null,
      }));
    }

    if (year && Number(year) > new Date().getFullYear()) {
      setDateError('Year founded cannot be in the future');

      return;
    }

    if (year) {
      setForm(prevForm => ({
        ...prevForm,
        year_founded: year,
      }));

      setDateError('');
    }
  };

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} Company`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <ImageLoader
          value={form.image_path || ''}
          sx={{
            borderRadius: '50%',
            width: 150,
            height: 150,
            margin: '0 auto 20px',
          }}
          {...{
            ...(isAdmin || isSuperAdmin ? {} : { aspectRatio: 9 / 16 }),
          }}
          onChange={onSelectImage}
        />

        {dirty && errors.image_path.dirty && (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="error"
            sx={{
              mt: 1,
            }}
          >
            Logo is required
          </Typography>
        )}
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Company name"
          placeholder="Company name"
          value={form.name}
          name="name"
          onInput={onInput}
          error={dirty && errors.name.dirty}
          helperText={dirty && mappingErrors(errors?.name?.message)}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Description"
          value={form.description}
          placeholder="Description"
          name="description"
          multiline
          rows={4}
          error={dirty && errors.description.dirty}
          helperText={dirty && mappingErrors(errors?.description?.message)}
          onInput={onInput}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Company email"
          placeholder="Company email"
          value={form.company_email}
          name="company_email"
          type="email"
          onInput={onInput}
          error={dirty && errors.company_email.dirty}
          helperText={dirty && mappingErrors(errors?.company_email?.message)}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Company phone number"
          placeholder="Company phone number"
          value={form.phone_number}
          name="phone_number"
          type="tel"
          onInput={onInput}
          error={dirty && errors.phone_number.dirty}
          helperText={dirty && mappingErrors(errors?.phone_number?.message)}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Company Headquarters"
          placeholder="Company Headquarters"
          value={form.address}
          name="address"
          type="text"
          onInput={onInput}
          error={dirty && errors.address.dirty}
          helperText={dirty && mappingErrors(errors?.address?.message)}
        />
      </FormControl>

      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={['DatePicker']}
            sx={{
              height: '100% !important',
            }}
          >
            <DatePicker
              label="Founded"
              view="year"
              views={['year']}
              maxDate={dayjs(new Date())}
              sx={{ width: '100%', height: '100% !important' }}
              value={form.year_founded ? dayjs(form.year_founded) : null}
              disableFuture
              onChange={handleSelectedYear}
            />
          </DemoContainer>

          {dateError && (
            <Typography
              variant="subtitle2"
              display="block"
              marginTop="4px"
              lineHeight={1.2}
              color="error"
              ml={1.75}
            >
              {dateError}
            </Typography>
          )}
        </LocalizationProvider>
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Linkedin Link"
          placeholder="Linkedin Link"
          value={form.linkedin_link}
          name="linkedin_link"
          type="text"
          onInput={onInput}
          error={dirty && errors.linkedin_link.dirty}
          helperText={dirty && mappingErrors(errors?.linkedin_link?.message)}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="X Link"
          placeholder="X Link"
          value={form.x_link}
          name="x_link"
          type="text"
          onInput={onInput}
          error={dirty && errors.x_link.dirty}
          helperText={dirty && mappingErrors(errors?.x_link?.message)}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-name-label-1">Company size</InputLabel>

        <Select
          labelId="demo-multiple-name-label-1"
          value={selectedEmployeesSize}
          label="Company size"
          onChange={handleSelectedEmployeesSize}
        >
          {employees.map(employer => (
            <MenuItem key={employer} value={employer}>
              {employer}
            </MenuItem>
          ))}
        </Select>

        {dirty && errors.company_size.dirty && (
          <Typography
            variant="subtitle2"
            display="block"
            marginTop="4px"
            lineHeight={1.2}
            color="error"
            ml={1.75}
          >
            {mappingErrors(errors.company_size.message)}
          </Typography>
        )}
      </FormControl>

      <FormControl>
        <Autocomplete
          multiple
          options={
            subcategories as {
              name: string;
              value: string;
              parent_id: number;
            }[]
          }
          getOptionLabel={(
            option: Pick<TCategoryItem, 'name' | 'value' | 'parent_id'>,
          ) =>
            `${option.name}  ${categories.find(category => category.id === option.parent_id) ? `| ${categories.find(category => category.id === option.parent_id)?.name}` : ''}`
          }
          defaultValue={company?.categories || []}
          onChange={(_, value) => onSelectCategories(value as TCategoryItem[])}
          renderInput={params => (
            <DebounceInput
              {...params}
              handleDebounce={onSearchCategories}
              debounceTimeout={300}
              label="Subcategories"
              placeholder="Subcategories"
              error={dirty && errors.categories.dirty}
            />
          )}
          noOptionsText="No categories"
        />

        {dirty && errors.categories.dirty && (
          <Typography
            variant="subtitle2"
            display="block"
            marginTop="4px"
            lineHeight={1.2}
            color="error"
            ml={1.75}
          >
            {mappingErrors(errors.categories.message)}
          </Typography>
        )}
      </FormControl>
      <FormControl>
        <TextField
          label="Website URL"
          placeholder="Enter website URL"
          value={form.site_url}
          name="site_url"
          type="text"
          onInput={onInput}
          error={dirty && errors.site_url.dirty}
          helperText={dirty && mappingErrors(errors?.site_url?.message)}
        />
      </FormControl>

      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          value={form.status}
          onChange={onChangeStatus}
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value={'claimed' as TCompanyForm['status']}
            control={<Radio />}
            label="Claimed"
          />
          <FormControlLabel
            value={'unclaimed' as TCompanyForm['status']}
            control={<Radio />}
            label="Unclaimed"
          />
          <FormControlLabel
            value={'deactivated' as TCompanyForm['status']}
            control={<Radio />}
            label="Deactivated"
          />
        </RadioGroup>
      </FormControl>
    </FormModal>
  );
};

export default CompanyForm;
