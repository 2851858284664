import httpClient from '@/core/client/http.client';
import { TGetWebsites, WebsiteItemType } from './websites.api.types';
import { TResponse } from '@/shared/types/promises.types';

const getWebsitesList: TGetWebsites = async params => {
  const response = await httpClient.get<TResponse<WebsiteItemType[]>>(
    '/web-resources',
    {
      params,
    },
  );
  return response;
};

const methods = {
  getWebsitesList,
};

export default methods;
