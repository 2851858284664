import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TypeItem from './TypeItem';
import TableElements from '@/shared/components/StyledTableCell';

import { Tid } from '@/shared/types/promises.types';
import { TTypeItem, TTypesGetParams } from '@/shared/api/types/types.api.types';
import Pagination from '@/shared/components/Pagination';

type Props = {
  types: TTypeItem[];
  params: TTypesGetParams;
  pages: number;
  loading: boolean;
  onEdit: (id: Tid) => void;
  onDelete: (id: Tid) => void;
  onChangePage: (page: string) => void;
  // onSort: (field: string) => void;
};

export default function TypesList(props: Props) {
  const { types, loading, onEdit, onDelete, onChangePage, pages, params } =
    props;

  // const checkSortDirection = (field: string) => {
  //   if (params.sort?.includes(field)) {
  //     return params.sort.includes('-') ? 'desc' : 'asc';
  //   }

  //   return 'desc';
  // };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell
              // sortable
              // sortDirection={checkSortDirection('name')}
              // onClick={() => onSort('name')}
              >
                Name
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center">
                Status
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Created
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center"></TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              types.map(type => (
                <TypeItem
                  key={type.id}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  {...type}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pages={pages}
        page={Number(params.page) || 1}
        onChange={(_, value) => onChangePage(String(value))}
      />
    </>
  );
}
