import {
  RetriveCompanyInfoFromType,
  TCompanyForm,
  TCompanyUpdateForm,
} from '../types';

import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';

import httpClient from '@/core/client/http.client';
import { fetchCompaniesList } from '@/shared/api/compnaies/compnaies.api';

type TCreateCompany = (
  data: TCompanyForm,
) => Promise<{ data: CompanyItemType }>;

type TUpdateCompany = (
  id: string | number,
  data: TCompanyUpdateForm,
) => Promise<{ data: CompanyItemType }>;

type TDeleteCompany = (id: string | number) => Promise<void>;

type TGetCompanyById = (
  id: string | number,
) => Promise<CompanyItemType | undefined>;

const getCompanyById: TGetCompanyById = async id => {
  return httpClient.get<CompanyItemType>(`/company/${id}`);
};

export const createCompany: TCreateCompany = async data => {
  return httpClient.post<TCompanyForm, { data: CompanyItemType }>(
    '/company',
    data,
  );
};

export const updateCompany: TUpdateCompany = async (id, data) => {
  return httpClient.put<TCompanyUpdateForm, { data: CompanyItemType }>(
    `/company/${id}`,
    data,
  );
};

export const deleteCompany: TDeleteCompany = async id => {
  return httpClient.delete(`/company/${id}`);
};

export const retrieveCompanyInfo = async (form: RetriveCompanyInfoFromType) => {
  return httpClient.post('/company/retrieve-info', form);
};

const methods = {
  getCompanyById,
  fetchCompaniesList,
  createCompany,
  updateCompany,
  deleteCompany,
  retrieveCompanyInfo,
};

export default methods;
