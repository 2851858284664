export const style = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  overflow: 'auto',
  p: 4,
};
