import httpClient from '@/core/client/http.client';
import { UserModelType } from './user.api.types';

const fetchCurrentUser = async (): Promise<{
  data: UserModelType;
  status: string;
}> => {
  return await httpClient.get('/user/profile/me');
};

const methods = {
  fetchCurrentUser,
};

export default methods;
