import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CategoryItem from './CategoryItem';
import TableElements from '@/shared/components/StyledTableCell';

import {
  TCategoryItem,
  TCategoriesGetParams,
} from '@/shared/api/categories/categories.api.types.d';
import { Tid } from '@/shared/types/promises.types';
import Pagination from '@/shared/components/Pagination';

type Props = {
  categories: TCategoryItem[];
  params: TCategoriesGetParams;
  loading: boolean;
  pages: number;
  onEdit: (id: Tid) => void;
  onDelete: (id: Tid) => void;
  onSort: (field: TCategoriesGetParams['order_by']) => void;
  onChangePage: (page: string) => void;
};

export default function CategoriesList(props: Props) {
  const {
    categories,
    loading,
    params,
    pages,
    onSort,
    onEdit,
    onDelete,
    onChangePage,
  } = props;

  const checkSortDirection = (field: TCategoriesGetParams['order_by']) => {
    if (params.order_by?.includes(field as string)) {
      return params.order_by.includes('-') ? 'desc' : 'asc';
    }

    return 'desc';
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell align="center">
                Image
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell
                sortable
                sortDirection={checkSortDirection('name')}
                onClick={() => onSort('name')}
              >
                Name
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Category Type
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center">
                Status
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center"></TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              categories.map(category => (
                <CategoryItem
                  key={category.id}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  {...category}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pages={pages}
        page={Number(params.page) || 1}
        onChange={(_, value) => onChangePage(String(value))}
      />
    </>
  );
}
