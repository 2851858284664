// import { Link } from 'react-router-dom';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableElements from '@/shared/components/StyledTableCell';
import { Tid } from '@/shared/types/promises.types';
import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';

type Props = CompanyItemType & {
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
  onRetrieve?: (name: string) => void;
};

export default function CategoryItem(props: Props) {
  const {
    id,
    address,
    company_email,
    image_path,
    name,
    phone_number,
    site_url,
    status,
    onEdit,
    onDelete,
    onRetrieve,
  } = props;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleRetrieve = () => {
    if (onRetrieve) {
      onRetrieve(name);
    }
  };

  return (
    <TableElements.StyledTableRow>
      <TableElements.StyledTableCell component="th" scope="row">
        {/* <Link to={`/companies/${id}`}> */}
        <Box display="flex" alignItems="center">
          <Avatar
            alt={name}
            src={image_path}
            variant="rounded"
            sx={{ width: 56, height: 56, marginRight: 2 }}
          />
          <Typography variant="body1">{name}</Typography>
        </Box>
        {/* </Link> */}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {site_url}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {company_email}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {phone_number}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center">
        {address}
      </TableElements.StyledTableCell>

      <TableElements.StyledTableCell align="center">
        {status}
      </TableElements.StyledTableCell>
      <TableElements.StyledTableCell align="center" sx={{ minWidth: '230px' }}>
        <IconButton onClick={handleEdit}>
          <VisibilityIcon color="success" />
        </IconButton>

        <IconButton onClick={handleRemove}>
          <DeleteIcon color="error" />
        </IconButton>

        <IconButton onClick={handleRetrieve}>
          <AutorenewIcon color="action" />
        </IconButton>
      </TableElements.StyledTableCell>
    </TableElements.StyledTableRow>
  );
}
