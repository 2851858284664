import { useState } from 'react';
import './styles.css';
import {
  // Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  // Typography,
  Box,
  Button,
  Typography,
} from '@mui/material';

// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { TCategoryForm } from '../types';
import { Tid } from '@/shared/types/promises.types';
import { TCategoryItem } from '@/shared/api/categories/categories.api.types.d';

import FormModal from '@/shared/components/Forms/FormModal';

import useValidate, { Rules } from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';
import { useNotify } from '@/shared/context/notifyContext';

type Props = {
  category?: TCategoryItem | null | undefined;
  loading: boolean;
  onCreate: (form: TCategoryForm) => void;
  onUpdate: (id: Tid, form: TCategoryForm) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
};

const CategoryForm = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { category, onCancel, onCreate, onUpdate, loading } = props;
  const { setNotify } = useNotify();

  const [isEdit] = useState<boolean>(!!category);

  const [form, setForm] = useState<TCategoryForm>({
    name: category?.name || '',
    description: category?.description || '',
    category_type: category?.category_type || 'services',
    is_popular: category?.is_popular || false,
    image_svg_content: category?.image_svg_content || '',
  });

  const rules: Rules<TCategoryForm> = {
    name: {
      required: true,
      maxLength: 100,
    },
    description: {
      required: false,
    },
    category_type: {
      required: false,
    },

    image_svg_content: {
      required: true,
    },

    is_popular: {
      required: false,
    },
  };

  const { dirty, touch, errors } = useValidate<TCategoryForm>(form, rules);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    if (touch()) {
      return;
    }

    if (isEdit && category) {
      onUpdate(category.id, form);
    } else {
      onCreate(form);
    }
  };

  const onInput = (value: string, type: 'name' | 'description') => {
    setForm(prevForm => ({
      ...prevForm,
      [type]: value,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectCategoryType = (value: TCategoryForm['category_type']) => {
    setForm(prevForm => ({
      ...prevForm,
      category_type: value,
    }));
  };

  // const onSetPopular = (checked: boolean) => {
  //   setForm(prevForm => ({
  //     ...prevForm,
  //     is_popular: checked,
  //   }));
  // };

  const onSetImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    if (file.type !== 'image/svg+xml') {
      setNotify({
        message: 'Only svg file is allowed',
        type: 'error',
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const svgBase64 = reader.result as string;

      let svgDecoded = atob(svgBase64.split(',')[1]);

      const newWidth = 40;
      const newHeight = 40;

      const regex = /width="(\d+)" height="(\d+)"/g;
      const updatedSvgString = svgDecoded.replace(
        regex,
        `width="${newWidth}" height="${newHeight}"`,
      );

      setForm(prevForm => ({
        ...prevForm,
        image_svg_content: updatedSvgString,
      }));
    };

    reader.readAsDataURL(file);
  };

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} Category`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div
            className="category_selected_svg"
            dangerouslySetInnerHTML={{ __html: form.image_svg_content }}
          />

          <Button
            variant="contained"
            color={dirty && errors.name.dirty ? 'error' : 'primary'}
          >
            <label htmlFor="image_svg_content">
              {form.image_svg_content ? 'Change Image' : 'Upload Image'}
            </label>
          </Button>

          <input
            id="image_svg_content"
            placeholder="Svg Image"
            name="image_svg_content"
            type="file"
            accept=".svg"
            hidden
            onChange={onSetImage}
          />

          {dirty && errors.image_svg_content.dirty && (
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="error"
              sx={{
                mt: 1,
              }}
            >
              Image is required
            </Typography>
          )}
        </Box>
      </FormControl>
      <FormControl sx={{ width: '100%' }}>
        <TextField
          placeholder="Category name"
          value={form.name}
          name="name"
          error={dirty && errors.name.dirty}
          helperText={dirty && mappingErrors(errors?.name?.message)}
          onInput={event =>
            onInput((event?.target as HTMLInputElement)?.value || '', 'name')
          }
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          value={form.description}
          name="description"
          placeholder="Description"
          multiline
          rows={4}
          error={dirty && errors.description.dirty}
          helperText={dirty && mappingErrors(errors?.description?.message)}
          onInput={event =>
            onInput(
              (event?.target as HTMLInputElement)?.value || '',
              'description',
            )
          }
        />
      </FormControl>
      {/* <ReactQuill
        theme="snow"
        value={form.description || ''}
        placeholder="Description"
        onChange={value => onInput(value, 'description')}
      />

      {dirty && errors.description.dirty && (
        <Typography
          variant="subtitle2"
          display="block"
          marginTop="4px"
          lineHeight={1.2}
          color="error"
          sx={{
            mt: 5,
          }}
        >
          {mappingErrors(errors.description.message)}
        </Typography>
      )} */}

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={form.category_type}
        sx={{
          mt: dirty && errors.description.dirty ? 0 : 5,
        }}
        onChange={(_, value) =>
          onSelectCategoryType(value as TCategoryForm['category_type'])
        }
      >
        <FormControlLabel
          value="services"
          control={<Radio />}
          label="Services"
        />
        <FormControlLabel
          value="software"
          control={<Radio />}
          label="Software"
        />
      </RadioGroup>

      {/* <FormControlLabel
        control={
          <Checkbox value={form.is_popular} defaultChecked={form.is_popular} />
        }
        label="Popular Category"
        onChange={(_, checked) => onSetPopular(checked)}
      /> */}
    </FormModal>
  );
};

export default CategoryForm;
