import { TResponse } from '@/shared/types/promises.types.d';

import { TCategoryItem } from '@/shared/api/categories/categories.api.types.d';

import {
  TCategoryForm,
  TCreateCategory,
  TUpdateCategory,
  TDeleteCategory,
} from '../types';

import httpClient from '@/core/client/http.client';
import { GetCategoriesItems } from '@/shared/api/categories/categories.api';

const createCategory: TCreateCategory = async form => {
  return httpClient.post<TCategoryForm, TResponse<TCategoryItem>>(
    '/category',
    form,
  );
};

const updateCategory: TUpdateCategory = async (id, form) => {
  return httpClient.put<TCategoryForm, TResponse<TCategoryItem>>(
    `/category/${id}`,
    form,
  );
};

const deleteCategory: TDeleteCategory = async id => {
  return httpClient.delete(`/category/${id}`);
};

const categoriesApi = {
  getCategories: GetCategoriesItems,
  updateCategory,
  createCategory,
  deleteCategory,
};

export default categoriesApi;
