import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme();

const theme = createTheme({
  ...baseTheme,

  palette: {
    mode: 'light',
  },

  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", sans-serif',
        },
        h1: {
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '35px',
          letterSpacing: '-0.02em',

          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '48px',
            lineHeight: '50px',
            letterSpacing: '-0.032em',
          },

          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '64px',
            lineHeight: '64px',
            letterSpacing: '-0.05em',
          },
        },
        h2: {
          fontSize: '28px',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '40px',
          },
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '55px',
          },
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '110%',
          letterSpacing: '-0.55px',
        },
        h3: {
          fontSize: '22px',

          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '28px',
          },
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '40px',
          },
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '110%',
          letterSpacing: '-1.6px',
        },
        h4: {
          fontSize: '14px',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '20px',
          },
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '110%',
          letterSpacing: '-0.8px',
        },
        h5: {
          fontSize: '14px',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '18px',
          },
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          letterSpacing: '-0.72px',
        },
        button: {
          fontSize: '14px',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '20px',
          },
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '100%',
          letterSpacing: '-0.8px',
        },
        subtitle1: {
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '110%',
          letterSpacing: '-0.02em',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '18px',
            lineHeight: '140%',
          },
        },
        subtitle2: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '110%',
          letterSpacing: '-0.48px',
        },
        body1: {
          fontSize: '16px',
          [baseTheme.breakpoints.between('xs', 'sm')]: {
            fontSize: '12px',
          },
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '-0.32px',
        },
        body2: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '100%',
          letterSpacing: '-0.64px',
        },
      },
    },
  },
});

export default theme;
