import { TRoute } from '../../router/router.type';
import Categories from './categories.view';

const routes: TRoute[] = [
  {
    path: '/categories',
    name: 'categories',
    title: 'Categories',
    component: function () {
      return <Categories />;
    },
    exact: true,
  },
];

export default routes;
