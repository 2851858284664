import { Box } from '@mui/material';
import { TNotificationItem } from '../types';
import { Tid } from '@/shared/types/promises.types';

import NotificationItem from './notification-item';
import Pagination from '@/shared/components/Pagination';

type Props = {
  loading: boolean;
  page: number;
  pages: number;
  notifications: TNotificationItem[];
  onDelete: (id: Tid) => void;
  onChangePage: (value: number) => void;
};

const NotificationsList = (props: Props) => {
  const { notifications, page, pages, onDelete, onChangePage } = props;
  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr" rowGap={1.5}>
        {notifications.map(notification => (
          <NotificationItem
            {...notification}
            onDelete={onDelete}
            key={notification.id}
          />
        ))}
      </Box>

      <Pagination
        pages={pages}
        page={page}
        onChange={(_, value) => onChangePage(value)}
      />
    </>
  );
};

export default NotificationsList;
