import { Link } from 'react-router-dom';

import { TNotificationItem } from '../types';
import { IconButton, Paper, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tid } from '@/shared/types/promises.types';
import formatTimeDifference from '@/shared/helpers/format-date.helper';

type Props = TNotificationItem & {
  onDelete: (id: Tid) => void;
};

const NotificationItem = (props: Props) => {
  const { created_at, body, id, title, viewed, onDelete } = props;

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onDelete) {
      onDelete(id);
    }
  };
  return (
    <Link
      to={{
        pathname: `/notifications/${id}`,
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          borderRadius: 2,
          columnGap: 2,
          backgroundColor: viewed
            ? 'rgba(0, 0, 0, 0.1)'
            : 'rgba(0, 0, 0, 0.05)',

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            transform: 'scale(1.01)',
            transition: '0.2s',
            cursor: 'pointer',
          },
        }}
      >
        <Typography variant="h5">{title}</Typography>
        <Typography marginRight={1} variant="caption">
          {body}
        </Typography>
        <Typography marginLeft="auto">
          {formatTimeDifference(created_at)}
        </Typography>

        <IconButton onClick={handleRemove}>
          <DeleteIcon color="error" />
        </IconButton>
      </Paper>
    </Link>
  );
};

export default NotificationItem;
