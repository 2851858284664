function base64ToFormData(base64String: string, fileName?: string): File {
  const byteString = atob(base64String.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: 'image/jpeg' });

  const file = new File([blob], fileName || 'image.jpg', {
    type: 'image/jpeg',
  });

  console.log(file);

  return file;
}

export default base64ToFormData;
