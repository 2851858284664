import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Paper, Typography } from '@mui/material';
import { ResourcesItemType } from '@/shared/api/resources/resources.types';
import ResourcesApi from '@/shared/api/resources/resources.api';
import NotificationApi from '../../api/notification.api';
import { TNotificationItem } from '../../types';
import { Tid } from '@/shared/types/promises.types';

const NotificationContainer = () => {
  const { id } = useParams<{ id: string }>();

  const [notification, setNorification] = useState<TNotificationItem | null>(
    null,
  );

  const [resource, setResource] = useState<ResourcesItemType | null>(null);

  const setViewedNotification = async () => {
    await NotificationApi.setViewedNotification(id || '12', {
      viewed: true,
    });
  };

  const fetchResourceById = async (id: Tid) => {
    const { data } = await ResourcesApi.fetchResourceById(id);

    setResource(data);
  };

  const fetchNotification = async () => {
    const { data } = await NotificationApi.getNotificationById(id || '12');

    if (data.meta_data?.resource_id) {
      await fetchResourceById(data.meta_data?.resource_id);
    }

    setNorification(data);
  };

  useEffect(() => {
    fetchNotification();
    setViewedNotification();
  }, [id]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          INKHUB Admin Pange | {notification?.title || 'Notification'}
        </title>
      </Helmet>

      <Box>
        <Link
          to={{
            pathname: '/notifications',
          }}
        >
          <Typography variant="caption" marginRight={2}>
            Back to notifications
          </Typography>
        </Link>
        <Box display="flex" alignItems="center" marginBottom={10} marginTop={4}>
          <Typography variant="h1" marginRight={2}>
            {notification?.title}
          </Typography>
        </Box>

        <Box display="flex" alignItems="flex-start">
          <Paper
            sx={{
              display: 'inline-block',
              marginRight: 2,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" padding={2}>
                Name:
              </Typography>
              <Typography variant="body1" padding={2} fontWeight={800}>
                {notification?.meta_data?.name}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" padding={2}>
                Surname:
              </Typography>
              <Typography variant="body1" padding={2} fontWeight={800}>
                {notification?.meta_data?.surname}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" padding={2}>
                Email:
              </Typography>
              <Typography variant="body1" padding={2} fontWeight={800}>
                {notification?.meta_data?.email}
              </Typography>
            </Box>
          </Paper>

          <Paper
            sx={{
              padding: 2,
            }}
          >
            <a href={notification?.meta_data?.resource_page} target="_blank">
              <Typography variant="body1" fontWeight={800} mb={2}>
                {resource?.name}
              </Typography>
            </a>

            <img src={resource?.image_path} width={250} />
          </Paper>
        </Box>
      </Box>
    </>
  );
};
export default NotificationContainer;
