import httpClient from '@/core/client/http.client';
import { TFormParams, TGetUserList, TRemoveUser, TUserItem } from '../types.d';
import { Tid, TResponse } from '@/shared/types/promises.types';

const getUserList: TGetUserList = async (params, signal) => {
  return httpClient.get<TResponse<TUserItem[]>>('/users', { params, signal });
};

const createUser = async (form: TFormParams): Promise<TUserItem> => {
  return httpClient.post<TFormParams, TUserItem>('/create-user', form);
};

const updateUser = async (id: Tid, form: TFormParams): Promise<TUserItem> => {
  return httpClient.patch<TFormParams, TUserItem>(`/update-user/${id}`, form);
};

const removeUser: TRemoveUser = async id => {
  return httpClient.delete(`/delete-user/${id}`);
};

const methods = {
  getUserList,
  createUser,
  updateUser,
  removeUser,
};

export default methods;
