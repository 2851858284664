import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  TCategoryItem,
  TCategoriesGetParams,
} from '@/shared/api/categories/categories.api.types.d';

import { TCategoryForm } from './types';

import {
  TOnCreateItem,
  TOnRemoveItem,
  TOnEditItem,
} from '@/shared/types/methods.types';

import { Tid } from '@/shared/types/promises.types';

import CategoriesDataApi from './api/categories.api';

import CategoriesList from './components/CategoriesList';
import CategoryForm from './forms/category.form';

import ContentLayout from '@/shared/layouts/ContentLayout';
import SearchBlock from '@/shared/components/SearchBlock';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import { useNotify } from '@/shared/context/notifyContext';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { popularityFilter, typesFilter } from '@/shared/constants/filter.const';
import {
  PopularityFilterType,
  TypesFilterType,
} from '@/shared/types/general.types';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';

type TOnRemoveCategory = TOnRemoveItem;
type TOnCreateCategory = TOnCreateItem<TCategoryForm>;
type TOnEditCategory = TOnEditItem<TCategoryForm>;

const CategoriesContainer = () => {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<TCategoriesGetParams>({});
  const [offset] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);

  const [categories, setCategories] = useState<TCategoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingCategoryForm, setLoadingCategoryForm] =
    useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] =
    useState<TCategoryItem | null>(null);

  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const [selectedCategoryType, setSelectedCategoryType] =
    useState<TypesFilterType['value']>('all');

  const [selectedCategoryPopular, setSelectedCategoryPopular] =
    useState<PopularityFilterType['value']>('all');

  const onClearModalState = () => {
    setRemoveModal(false);
    setSelectedCategory(null);
    setOpenModal(false);
  };

  const onSetSelectedCategory = (id: Tid) => {
    const category = categories.find(category => category.id === id);

    setSelectedCategory(category || null);
  };

  const handleClose = () => {
    onClearModalState();
    setLoadingCategoryForm(false);
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedCategory(null);
  };

  const handleOpenRemoveModal = (id: Tid) => {
    setRemoveModal(true);
    setOpenModal(true);
    onSetSelectedCategory(id);
  };

  const handleOpenEditModal = (id: Tid) => {
    setOpenModal(true);
    onSetSelectedCategory(id);
  };

  const fetchCategories = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);

      try {
        const { data, total_items } = await CategoriesDataApi.getCategories(
          {
            ...queries,
            pageCount: `${offset}`,
            parentId: 0,
          },
          signal,
        );

        const total = total_items ? total_items : 0;

        setPages(Math.ceil(total / offset));

        setCategories(data);

        setEmptyRequests(prev => prev + 1);
      } catch (error) {
        console.error('error', error);
      } finally {
        setLoading(false);
      }
    },
    [queries],
  );

  const onSortCategories = (sort: TCategoriesGetParams['order_by']) => {
    const newSort = queries.order_by?.includes(sort as string)
      ? queries.order_by.includes('-')
        ? sort
        : `-${sort}`
      : sort;

    setQueries({
      ...queries,
      order_by: newSort as TCategoriesGetParams['order_by'],
      page: 1,
    });
  };

  const onSearchCategories = (search: string) => {
    if (search.length === 0 && epmtyRequests !== 1) {
      setQueries({ ...queries, query: undefined, page: 1 });
      return;
    }

    if (search.length >= 3) {
      setQueries({ ...queries, query: search, page: 1 });
      return;
    }
  };

  const onSelectedCategoryType = (
    categoryType: SelectChangeEvent<TypesFilterType['value']>,
  ) => {
    setSelectedCategoryType(
      categoryType.target.value as TypesFilterType['value'],
    );

    const value: TypesFilterType['value'] =
      (categoryType.target.value as TypesFilterType['value']) || 'all';

    if (value) {
      if (value === 'all') {
        setQueries({ ...queries, categoryType: undefined, page: 1 });
      } else {
        setQueries({
          ...queries,
          categoryType: value,
          page: 1,
        });
      }
    }
  };

  const onSelectedCategoryPopular = (
    categoryPopular: SelectChangeEvent<PopularityFilterType['value']>,
  ) => {
    setSelectedCategoryPopular(
      categoryPopular.target.value as PopularityFilterType['value'],
    );

    const value: PopularityFilterType['value'] =
      (categoryPopular.target.value as PopularityFilterType['value']) || 'all';

    if (value === 'all') {
      setQueries({ ...queries, isPopular: undefined, page: 1 });
    } else {
      setQueries({ ...queries, isPopular: value, page: 1 });
    }
  };

  const onRemoveCategory: TOnRemoveCategory = async () => {
    if (!selectedCategory) return;

    setLoadingCategoryForm(true);

    try {
      await CategoriesDataApi.deleteCategory(selectedCategory.id);

      fetchCategories();

      onClearModalState();

      setNotify({ message: 'Category removed', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingCategoryForm(false);
    }
  };

  const onEditCategory: TOnEditCategory = async (id, form) => {
    if (!selectedCategory) return;

    setLoadingCategoryForm(true);

    try {
      const response = await CategoriesDataApi.updateCategory(id, form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      const { data } = response;

      setCategories(
        categories.map(category => (category.id === id ? data : category)),
      );

      onClearModalState();

      setNotify({ message: 'Category updated', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingCategoryForm(false);
    }
  };

  const onCreateCategory: TOnCreateCategory = async form => {
    setLoadingCategoryForm(true);

    try {
      const response = await CategoriesDataApi.createCategory(form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      fetchCategories();

      onClearModalState();

      setNotify({ message: 'Category created', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingCategoryForm(false);
    }
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchCategories(controller.signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const memoizedCategories = useMemo(() => categories, [categories]);

  return (
    <ContentLayout
      title="Categories"
      buttonTitle="Create new category"
      removeTitleModal="Remove category"
      removeTitleMessage={`Are you sure you want to remove category ${selectedCategory?.name}?`}
      loading={loading}
      formLoading={loadingCategoryForm}
      openModal={openModal}
      isRemoveModal={!!removeModal}
      selectedItem={selectedCategory}
      onRemoveItem={onRemoveCategory}
      onCloseModal={handleClose}
      onCreate={handleOpenCreateModal}
      form={
        <CategoryForm
          loading={loadingCategoryForm}
          category={selectedCategory}
          onCancel={handleClose}
          onCreate={onCreateCategory}
          onUpdate={onEditCategory}
        />
      }
    >
      <SearchBlock
        value={queries.query || ''}
        placeholder="Search category by name"
        onInput={onSearchCategories}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />
      <Box
        display={'grid'}
        marginBottom={3}
        columnGap={2}
        gridTemplateColumns="repeat(2, 200px)"
      >
        <Select
          value={selectedCategoryType === undefined ? '' : selectedCategoryType}
          label="Category Type"
          onChange={onSelectedCategoryType}
        >
          {typesFilter.map(type => (
            <MenuItem key={type.id} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </Select>

        <Select
          value={
            selectedCategoryPopular === undefined ? '' : selectedCategoryPopular
          }
          label="Category popularity"
          onChange={onSelectedCategoryPopular}
        >
          {popularityFilter.map(popularity => (
            <MenuItem key={popularity.id} value={popularity.value}>
              {popularity.name}
            </MenuItem>
          ))}
        </Select>

        {/* <DropdownList
          items={popularityFilter}
          selected={
            typeof queries.isPopular === 'boolean'
              ? queries.isPopular
                ? 'Popular'
                : 'Not popular'
              : ''
          }
          label="Category popularity"
          onSelect={item =>
            onSelectedCategoryPopular(item as PopularityFilterType)
          }
        /> */}
      </Box>

      <CategoriesList
        categories={memoizedCategories}
        pages={pages}
        params={queries}
        loading={loading}
        onDelete={handleOpenRemoveModal}
        onEdit={handleOpenEditModal}
        onSort={onSortCategories}
        onChangePage={onChangePage}
      />
    </ContentLayout>
  );
};

export default CategoriesContainer;
