import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  TCategoryItem,
  TCategoriesGetParams,
} from '@/shared/api/categories/categories.api.types.d';

import { TSubCategoryForm } from './types';

import {
  TOnCreateItem,
  TOnRemoveItem,
  TOnEditItem,
} from '@/shared/types/methods.types';

import { Tid } from '@/shared/types/promises.types';

import SubCategoriesDataApi from './api/subcategories.api';
import CategoriesDataApi from '@/shared/api/categories/categories.api';

import SubCategoriesList from './components/SubCategoriesList';
import SubCategoryForm from './forms/subcategory.form';

import ContentLayout from '@/shared/layouts/ContentLayout';
import SearchBlock from '@/shared/components/SearchBlock';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import { useNotify } from '@/shared/context/notifyContext';

import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

type TOnRemoveSubCategory = TOnRemoveItem;
type TOnCreateCategory = TOnCreateItem<TSubCategoryForm>;
type TOnEditSubCategory = TOnEditItem<TSubCategoryForm>;

const CategoriesContainer = () => {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<TCategoriesGetParams>({});
  const [offset] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);

  const [categories, setCategories] = useState<TCategoryItem[]>([]);
  const [subcategories, setSubcategories] = useState<TCategoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingCategoryForm, setLoadingSubCategoryForm] =
    useState<boolean>(false);

  const [selectedSubCategory, setSelectedSubCategory] =
    useState<TCategoryItem | null>(null);

  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const onClearModalState = () => {
    setRemoveModal(false);
    setSelectedSubCategory(null);
    setOpenModal(false);
  };

  const onSetSelectedSubCategory = (id: Tid) => {
    const subcategory = subcategories.find(
      subcategory => subcategory.id === id,
    );

    setSelectedSubCategory(subcategory || null);
  };

  const handleClose = () => {
    onClearModalState();
    setLoadingSubCategoryForm(false);
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedSubCategory(null);
  };

  const handleOpenRemoveModal = (id: Tid) => {
    setRemoveModal(true);
    setOpenModal(true);
    onSetSelectedSubCategory(id);
  };

  const handleOpenEditModal = (id: Tid) => {
    setOpenModal(true);
    onSetSelectedSubCategory(id);
  };

  const fetchCategories = async () => {
    setLoading(true);

    try {
      const { data } = await CategoriesDataApi.GetCategoriesItems({
        parentId: 0,
      });

      setCategories(data);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubCategories = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);

      try {
        const { data, total_items } =
          await SubCategoriesDataApi.getSubCategories(
            {
              ...queries,
              parentId: queries.parentId || -1,
              pageCount: `${offset}`,
            },
            signal,
          );

        const total = total_items ? total_items : 0;

        setPages(Math.ceil(total / offset));

        setSubcategories(data);

        setEmptyRequests(prev => prev + 1);
      } catch (error) {
        console.error('error', error);
      } finally {
        setLoading(false);
      }
    },
    [queries],
  );

  const onSortSubCategories = (sort: TCategoriesGetParams['order_by']) => {
    const newSort = queries.order_by?.includes(sort as string)
      ? queries.order_by.includes('-')
        ? sort
        : `-${sort}`
      : sort;

    setQueries({
      ...queries,
      order_by: newSort as TCategoriesGetParams['order_by'],
      page: 1,
    });
  };

  const onSearchSubCategories = (search: string) => {
    if (search.length === 0 && epmtyRequests !== 1) {
      setQueries({ ...queries, query: undefined, page: 1 });
      return;
    }

    if (search.length >= 3) {
      setQueries({ ...queries, query: search, page: 1 });
      return;
    }
  };

  const onRemoveSubCategory: TOnRemoveSubCategory = async () => {
    if (!selectedSubCategory) return;

    setLoadingSubCategoryForm(true);

    try {
      await SubCategoriesDataApi.deleteSubCategory(selectedSubCategory.id);

      fetchSubCategories();

      onClearModalState();

      setNotify({ message: 'Subcategory removed', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingSubCategoryForm(false);
    }
  };

  const onEditSubCategory: TOnEditSubCategory = async (id, form) => {
    if (!selectedSubCategory) return;

    setLoadingSubCategoryForm(true);

    try {
      const response = await SubCategoriesDataApi.updateSubCategory(id, form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      const { data } = response;

      setCategories(
        categories.map(category => (category.id === id ? data : category)),
      );

      onClearModalState();

      setNotify({ message: 'Category updated', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingSubCategoryForm(false);
    }
  };

  const onCreateSubCategory: TOnCreateCategory = async form => {
    setLoadingSubCategoryForm(true);

    try {
      const response = await SubCategoriesDataApi.createSubCategory(form);

      if (!response.data) {
        setNotify({
          type: 'error',
          message:
            'Something went wrong. Please try again later or contact support.',
        });

        return;
      }

      fetchSubCategories();

      onClearModalState();

      setNotify({ message: 'Category created', type: 'success' });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingSubCategoryForm(false);
    }
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  const onSelectCategory = (event: SelectChangeEvent<TCategoryItem['id']>) => {
    const category = categories.find(
      category => category.id === event.target.value,
    );

    setQueries({
      ...queries,
      parentId: category?.id || 0,
      page: 1,
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchSubCategories(controller.signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const memoizedSubCategories = useMemo(() => subcategories, [subcategories]);

  return (
    <ContentLayout
      title="Subcategories"
      buttonTitle="Create new subcategory"
      removeTitleModal="Remove subcategory"
      removeTitleMessage={`Are you sure you want to remove subcategory ${selectedSubCategory?.name}?`}
      loading={loading}
      formLoading={loadingCategoryForm}
      openModal={openModal}
      isRemoveModal={!!removeModal}
      selectedItem={selectedSubCategory}
      onRemoveItem={onRemoveSubCategory}
      onCloseModal={handleClose}
      onCreate={handleOpenCreateModal}
      form={
        <SubCategoryForm
          loading={loadingCategoryForm}
          categories={categories}
          subcategory={selectedSubCategory}
          onCancel={handleClose}
          onCreate={onCreateSubCategory}
          onUpdate={onEditSubCategory}
        />
      }
    >
      <SearchBlock
        value={queries.query || ''}
        placeholder="Search subcategory by name"
        onInput={onSearchSubCategories}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />

      <Box
        sx={{
          width: '100%',
          marginBottom: 2,
          maxWidth: '620px',
          display: 'flex',
        }}
      >
        <FormControl
          sx={{
            width: '100%',
            maxWidth: '320px',
            display: 'flex',
          }}
        >
          <InputLabel id="demo-multiple-name-label">Categories</InputLabel>

          <Select
            labelId="demo-multiple-name-label"
            value={parseInt(queries?.parentId as string) || -1}
            label="Categories"
            onChange={onSelectCategory}
          >
            {categories.map(category => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {queries?.parentId && (
          <Button
            sx={{ marginLeft: 1 }}
            onClick={() => setQueries({ ...queries, parentId: undefined })}
          >
            Clear Filter
          </Button>
        )}
      </Box>

      <SubCategoriesList
        subcategories={memoizedSubCategories}
        categories={categories}
        pages={pages}
        params={queries}
        loading={loading}
        onDelete={handleOpenRemoveModal}
        onEdit={handleOpenEditModal}
        onSort={onSortSubCategories}
        onChangePage={onChangePage}
      />
    </ContentLayout>
  );
};

export default CategoriesContainer;
