import { useEffect, useState } from 'react';
import SearchBlock from '@/shared/components/SearchBlock';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { GetParamsCompanyType } from '@/shared/api/compnaies/compnaies.api.types';

import {
  popularityFilter,
  typesFilter,
  // withCountFilter,
  statusesFilter,
} from '@/shared/constants/filter.const';

import {
  PopularityFilterType,
  TypesCountingFilterType,
  TypesFilterType,
  StatusesFilterType,
} from '@/shared/types/general.types';
import { TCategoryItem } from '@/shared/api/categories/categories.api.types';
import DebounceInput from '@/shared/components/DebounceInput';

type Props = {
  queries: GetParamsCompanyType;
  categories: TCategoryItem[];
  subcategories: TCategoryItem[];
  selectedCategiries: TCategoryItem[];
  onSearchCompanies: (value: string) => void;
  onSelectedCompanyType: (
    companyType: SelectChangeEvent<TypesFilterType['value']>,
  ) => void;
  onSelectedCompanyPopular: (
    companyPopular: SelectChangeEvent<PopularityFilterType['value']>,
  ) => void;
  onSelectedCompanyWithCounting: (
    companyCounting: SelectChangeEvent<TypesCountingFilterType['value']>,
  ) => void;

  onSelectedStatus: (
    status: SelectChangeEvent<StatusesFilterType['value']>,
  ) => void;

  onSearchCategories: (value: string) => void;
  onSelectedCategories: (value: string) => void;
};

function CompaniesFilters(props: Props) {
  const {
    queries,
    categories,
    subcategories,
    selectedCategiries,
    onSearchCompanies,
    onSelectedCompanyType,
    onSelectedCompanyPopular,
    // onSelectedCompanyWithCounting,
    onSearchCategories,
    onSelectedCategories,
    onSelectedStatus,
  } = props;

  const [selectedCompanyType, setSelectedCompanyType] = useState<
    TypesFilterType['value']
  >(queries.companyType || 'all');

  const [selectedCompanyPopular, setSelectedCompanyPopular] = useState<
    PopularityFilterType['value']
  >(queries.isPopular || 'all');

  // const [selectedCompanyWithCounting, setSelectedCompanyWithCounting] =
  //   useState<TypesCountingFilterType['value']>('all');

  const [filtersCategiries, setSelectedCategories] = useState<TCategoryItem[]>(
    [],
  );

  const [selectedStatus, setSelectedStatus] = useState<
    StatusesFilterType['value']
  >(queries.status || 'all');

  const handleSelectedCompanyType = (
    event: SelectChangeEvent<TypesFilterType['value']>,
  ) => {
    setSelectedCompanyType(event.target.value as TypesFilterType['value']);
    onSelectedCompanyType(event);
  };

  const handleSelectedCompanyPopular = (
    event: SelectChangeEvent<PopularityFilterType['value']>,
  ) => {
    setSelectedCompanyPopular(
      event.target.value as PopularityFilterType['value'],
    );
    onSelectedCompanyPopular(event);
  };

  // const handleSelectedCompanyWithCounting = (
  //   event: SelectChangeEvent<TypesCountingFilterType['value']>,
  // ) => {
  //   setSelectedCompanyWithCounting(
  //     event.target.value as TypesCountingFilterType['value'],
  //   );
  //   onSelectedCompanyWithCounting(event);
  // };

  const handleSelectedStatus = (
    event: SelectChangeEvent<StatusesFilterType['value']>,
  ) => {
    setSelectedStatus(event.target.value as StatusesFilterType['value']);

    onSelectedStatus(event);
  };

  const handleSearchCategories = (value: string) => {
    onSearchCategories(value);
  };

  const handleSelectedCategories = (value: TCategoryItem[]) => {
    setSelectedCategories(value);

    const categories = value.map(category => category.value).join(',');

    onSelectedCategories(categories);
  };

  useEffect(() => {
    setSelectedCategories(() => [...selectedCategiries]);
  }, [selectedCategiries]);

  return (
    <>
      <SearchBlock
        value={queries.query || ''}
        placeholder="Search company by name"
        onInput={onSearchCompanies}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />

      <Box
        display={'grid'}
        marginBottom={3}
        columnGap={2}
        gridTemplateColumns="repeat(4, 200px)"
      >
        <FormControl>
          <InputLabel id="demo-multiple-name-label">Company type</InputLabel>

          <Select
            labelId="demo-multiple-name-label"
            value={selectedCompanyType}
            label="Company type"
            onChange={handleSelectedCompanyType}
          >
            {typesFilter.map(type => (
              <MenuItem key={type.id} value={type.value}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-multiple-name-label-1">
            Company popularity
          </InputLabel>

          <Select
            labelId="demo-multiple-name-label-1"
            value={selectedCompanyPopular}
            label="Company popularity"
            onChange={handleSelectedCompanyPopular}
          >
            {popularityFilter.map(popularity => (
              <MenuItem key={popularity.id} value={popularity.value}>
                {popularity.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl>
          <InputLabel id="demo-multiple-name-label-2">
            Company with counting
          </InputLabel>

          <Select
            labelId="demo-multiple-name-label-2"
            value={selectedCompanyWithCounting}
            label="Company with counting"
            onChange={handleSelectedCompanyWithCounting}
          >
            {withCountFilter.map(filter => (
              <MenuItem key={filter.id} value={filter.value}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl>
          <InputLabel id="demo-multiple-name-label-3">Status</InputLabel>

          <Select
            labelId="demo-multiple-name-label-3"
            value={selectedStatus}
            label="Status"
            onChange={handleSelectedStatus}
          >
            {statusesFilter.map(status => (
              <MenuItem key={status.id} value={status.value}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <FormControl sx={{ width: '100%', maxWidth: 400, marginBottom: 3 }}>
        <Autocomplete
          multiple
          options={subcategories}
          getOptionLabel={option =>
            `${option.name} | ${categories.find(category => category.id === option.parent_id)?.name}`
          }
          value={filtersCategiries}
          onChange={(_, value) =>
            handleSelectedCategories(value as TCategoryItem[])
          }
          renderInput={params => (
            <DebounceInput
              {...params}
              handleDebounce={handleSearchCategories}
              debounceTimeout={300}
              label="Subcategories"
            />
          )}
        />
      </FormControl>
    </>
  );
}

export default CompaniesFilters;
