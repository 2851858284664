// import mockData from '../mock.data';
import httpClient from '@/core/client/http.client';

import WebsitesApi from '@/shared/api/websites/websites.api';
import {
  TGetWebsites,
  WebsiteItemType,
} from '@/shared/api/websites/websites.api.types';

import { WebsiteFormType, TWebsiteUpdateForm } from './../types.d';
import { Tid } from '@/shared/types/promises.types';

type TCreateWebsite = (form: WebsiteFormType) => Promise<WebsiteItemType>;
type TUpdateWebsite = (form: TWebsiteUpdateForm) => Promise<WebsiteItemType>;
type TDeleteWebsite = (id: string | number) => Promise<void>;

const getWebsites: TGetWebsites = async params => {
  return WebsitesApi.getWebsitesList(params);
};

const createWebsite: TCreateWebsite = async form => {
  return httpClient.post('/web-resource', form);
};

const updateWebsite: TUpdateWebsite = async ({ id, form }) => {
  return httpClient.put(`/web-resource/${id}`, form);
};

const deleteWebsite: TDeleteWebsite = async id => {
  return httpClient.delete(`/web-resource/${id}`);
};

const refreshWebsite = async (id: Tid, url: string) => {
  return httpClient.post('/web-resource/crawling-request', {
    id,
    url,
  });
};

const methods = {
  getWebsites,
  createWebsite,
  updateWebsite,
  deleteWebsite,
  refreshWebsite,
};

export default methods;
