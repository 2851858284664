import { TRoute } from '../../router/router.type';
import Login from './login.view';

const routes: TRoute[] = [
  {
    path: '/login',
    name: 'login',
    title: 'Login',
    component: function () {
      return <Login />;
    },
    exact: true,
  },
];

export default routes;
