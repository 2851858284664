import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { ProtectedRouteProps, TRouter } from './router.type';

import { TRoute } from './router.type';

const flattenDeep = (array: any[]): any[] =>
  array.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
    [],
  );

const generateFlattenRoutes = (routes: TRoute[] | undefined): any[] => {
  if (!routes) return [];

  return flattenDeep(
    routes.map(({ routes: subRoutes, ...rest }) => [
      rest,
      generateFlattenRoutes(subRoutes),
    ]),
  );
};

export const renderRoutes = (mainRoutes: TRouter[]) => {
  const Routes = ({ isAuthorized }: ProtectedRouteProps) => {
    const layouts = mainRoutes.map(
      ({ layout: Layout, routes, isProtected }, index) => {
        const subRoutes = generateFlattenRoutes(routes);

        return (
          <Route key={index} element={<Layout />}>
            <Route
              element={
                isProtected && <ProtectedRoute isAuthorized={isAuthorized} />
              }
            >
              {subRoutes.map(({ component: Component, path, name }) => {
                return (
                  Component &&
                  path && (
                    <Route key={name} element={<Component />} path={path} />
                  )
                );
              })}
            </Route>
          </Route>
        );
      },
    );
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };

  return Routes;
};
