import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import Pagination from '@/shared/components/Pagination';
import TableElements from '@/shared/components/StyledTableCell';

import ResourceItem from './ResourceItem';

import { GetParamsResourceType, ResourcesItemType } from '../types';
import { Tid } from '@/shared/types/promises.types';

type Props = {
  resources: ResourcesItemType[];
  params: GetParamsResourceType;
  loading: boolean;
  pages: number;
  onEdit: (id: Tid) => void;
  onDelete: (id: Tid) => void;
  onSort: (field: string) => void;
  onChangePage: (page: string) => void;
  onEditPreviews?: (id: Tid) => void;
};

export default function CategoriesList(props: Props) {
  const {
    resources,
    pages,
    params,
    onChangePage,
    onEdit,
    onDelete,
    onEditPreviews,
  } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell>
                Image
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Name
              </TableElements.StyledTableCell>

              {/* <TableElements.StyledTableCell align="center">
                Descriptions
              </TableElements.StyledTableCell> */}
              <TableElements.StyledTableCell>
                Company
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell
                align="center"
                sx={{ minWidth: 180 }}
              >
                Resource Type
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell>
                Keywords
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center"></TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map(resource => (
              <ResourceItem
                key={resource.id}
                onEdit={onEdit}
                onDelete={onDelete}
                onEditPreviews={onEditPreviews}
                {...resource}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pages={pages}
        page={Number(params.page) || 1}
        onChange={(_, value) => onChangePage(String(value))}
      />
    </>
  );
}
