import { useState } from 'react';
import { FormControl, OutlinedInput } from '@mui/material';
import { TTypeForm } from '../types';
import { Tid } from '@/shared/types/promises.types';
import { TTypeItem } from '@/shared/api/types/types.api.types';

import FormModal from '@/shared/components/Forms/FormModal';

type Props = {
  type?: TTypeItem | null;
  loading: boolean;
  onCreate: (form: TTypeForm) => void;
  onUpdate: (id: Tid, form: TTypeForm) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
};

const TypeForm = (props: Props) => {
  const { type, onCancel, onCreate, onUpdate, loading } = props;

  const [isEdit] = useState<boolean>(!!type);

  const [form, setForm] = useState<TTypeForm>({
    name: type?.name || '',
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    if (!form.name) {
      return;
    }

    if (isEdit && type) {
      onUpdate(type.id, form);
    } else {
      onCreate(form);
    }
  };

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));
  };

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} Type`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <OutlinedInput
          placeholder="Type name"
          value={form.name}
          name="name"
          onInput={onInput}
        />
        {/* <MyFormHelperText /> */}
      </FormControl>
    </FormModal>
  );
};

export default TypeForm;
