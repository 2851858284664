import React, { createContext, useContext, useState, ReactNode } from 'react';
import { UserModelType } from '../api/user/user.api.types';

type UpdateStateType = (newState: boolean) => void;
type UpdateUserType = (newUser: UserModelType | null) => void;

const AuthContext = createContext<
  | {
      authenticated: boolean;
      setAuthenticated: UpdateStateType;
      user: UserModelType | null;
      setUser: UpdateUserType;
    }
  | undefined
>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [authenticated, setState] = useState<boolean>(false);
  const [user, setUser] = useState<UserModelType | null>(null);

  const setAuthenticated: UpdateStateType = authenticated => {
    setState(authenticated);
  };

  return (
    <AuthContext.Provider
      value={{ authenticated, user, setAuthenticated, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthentication must be used within a AuthProvider');
  }
  return context;
};
