import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import WebsiteItem from './WebsiteItem';
import TableElements from '@/shared/components/StyledTableCell';

import {
  TGetWebsitesParams,
  WebsiteItemType,
} from '@/shared/api/websites/websites.api.types';
import Pagination from '@/shared/components/Pagination';
import { Tid } from '@/shared/types/promises.types';

type Props = {
  websites: WebsiteItemType[];
  params: TGetWebsitesParams;
  loading: boolean;
  pages: number;
  onEdit?: (id: Tid) => void;
  onDelete?: (id: Tid) => void;
  onChangePage: (page: string) => void;
  onRefresh: (id: Tid, url: string) => void;
};

export default function CategoriesList(props: Props) {
  const { websites, pages, params, onEdit, onDelete, onChangePage, onRefresh } =
    props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell align="center">
                URL
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell>
                Parsed Status
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center"></TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {websites.map(website => (
              <WebsiteItem
                key={website.id}
                onEdit={onEdit}
                onDelete={onDelete}
                onRefresh={onRefresh}
                {...website}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pages={pages}
        page={Number(params.page) || 1}
        onChange={(_, value) => onChangePage(String(value))}
      />
    </>
  );
}
