import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type Props = {
  onSubmit: (data: { email: string; password: string }) => void;
  isLoading: boolean;
  error?: string | null;
};

const LoginForm = (props: Props) => {
  const { onSubmit, isLoading } = props;

  const [form, setForm] = React.useState({
    email: '',
    password: '',
  });

  //   const [isRememberMe, setIsRememberMe] = React.useState(rememberMe);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(form);
  };

  return (
    <Box>
      <Typography variant="h2">Login to INKHUB Admin Panel</Typography>

      <Divider sx={{ marginY: 3 }} />

      <Box
        component="form"
        display="grid"
        gridTemplateColumns="1fr"
        rowGap={3}
        onSubmit={handleSubmit}
      >
        <FormControl sx={{ width: '100%' }}>
          <OutlinedInput
            placeholder="Email"
            value={form.email}
            name="email"
            required
            onInput={handleChange}
          />
          {/* <MyFormHelperText /> */}
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <OutlinedInput
            placeholder="Password"
            value={form.password}
            name="password"
            type="password"
            required
            onInput={handleChange}
          />
          {/* <MyFormHelperText /> */}
        </FormControl>
        <Divider sx={{ marginY: 1 }} />

        <LoadingButton
          disabled={isLoading}
          loading={isLoading}
          color="success"
          variant="contained"
          type="submit"
          size="large"
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default LoginForm;
