import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { TFetchItem, TOnRemoveItem } from '@/shared/types/methods.types';
import { Tid } from '@/shared/types/promises.types';

import ContentLayout from '@/shared/layouts/ContentLayout';
// import SearchBlock from '@/shared/components/SearchBlock';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import { useNotify } from '@/shared/context/notifyContext';

import { TNotificationItem } from './types';
import ApiDataNotification from './api/notification.api';
import NotificationsList from './components/notifications-list';

type TFetchNotificaiotns = TFetchItem;
type onRemoveTFetchNotificaiotn = TOnRemoveItem;

const NotificationsContainer = () => {
  const { setNotify } = useNotify();

  const [pages, setPages] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<{
    page: number;
  }>({
    page: 0,
  });

  const [norifications, setNorifications] = useState<TNotificationItem[]>([]);
  const [selectedNorification, setSelectedNorification] =
    useState<TNotificationItem | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCompanyForm, setLoadingCompanyForm] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClose = () => {
    setOpenModal(false);
    setSelectedNorification(null);
  };

  const onClearModalState = () => {
    setOpenModal(false);
    setSelectedNorification(null);
  };

  const onSetSelectedNorification = (id: Tid) => {
    const norification = norifications.find(
      norification => norification.id === id,
    );

    setSelectedNorification(norification || null);
  };

  const onOpenRemoveModal = (id: Tid) => {
    onSetSelectedNorification(id);
    setOpenModal(true);
  };

  // const onSearchNotification = (value: string) => {
  //   setQueries({ ...queries, search: value });
  // };

  const onFetchNorifications: TFetchNotificaiotns = async () => {
    setLoading(true);

    try {
      const { data } = await ApiDataNotification.getNotifications({
        offset: (queries.page === 0 ? 0 : queries.page - 1) * 10,
        limit: 10,
      });

      const { pagination, result } = data;

      if (result.length === 0) {
        setNotify({ type: 'info', message: 'No notifications found' });

        setPages(0);

        return;
      }

      const total = pagination.total ? pagination.total : 0;

      setPages(Math.ceil(total / pagination.limit));

      setNorifications(result);
    } catch (error) {
      setNotify({ type: 'error', message: 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const onRemoveNorification: onRemoveTFetchNotificaiotn = async () => {
    setLoadingCompanyForm(true);

    try {
      if (selectedNorification) {
        await ApiDataNotification.removeNotification(selectedNorification.id);

        setNotify({ type: 'success', message: 'Norification removed' });

        onFetchNorifications();

        onClearModalState();
      }
    } catch (error) {
      setNotify({ type: 'error', message: 'Something went wrong' });
    } finally {
      setLoadingCompanyForm(false);
    }
  };

  const onChangePage = (page: number) => {
    setQueries({ ...queries, page });
  };

  useEffect(() => {
    onFetchNorifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const memoizedNotification = useMemo(() => norifications, [norifications]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>INKHUB Admin Pange | Norification</title>
      </Helmet>

      <ContentLayout
        title="Notifications"
        removeTitleModal="Remove Norification"
        removeTitleMessage="Are you sure you want to remove this norification?"
        loading={loading}
        formLoading={loadingCompanyForm}
        isRemoveModal={true}
        openModal={openModal}
        onRemoveItem={onRemoveNorification}
        onCloseModal={handleClose}
      >
        {/* <SearchBlock
          value={queries.search || ''}
          placeholder="Search notification"
          onInput={onSearchNotification}
          sx={{ marginBottom: 4, maxWidth: 400 }}
        /> */}
        <NotificationsList
          loading={loading}
          page={queries.page}
          pages={pages}
          notifications={memoizedNotification}
          onDelete={onOpenRemoveModal}
          onChangePage={onChangePage}
        />
      </ContentLayout>
    </>
  );
};

export default NotificationsContainer;
