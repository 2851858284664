import { useEffect, useMemo, useState } from 'react';
import { WebsiteFormType, TWebsiteUpdateForm } from './types.d';

import WebsiteDataApi from './api/websites.api';

import ContentLayout from '../../shared/layouts/ContentLayout';
import WebsitesList from './components/WebsitesList';
import WebsiteFrom from './forms/website.form';
import {
  TGetWebsitesParams,
  WebsiteItemType,
} from '@/shared/api/websites/websites.api.types';
import { useNotify } from '@/shared/context/notifyContext';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import SearchBlock from '@/shared/components/SearchBlock';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';
import { Tid } from '@/shared/types/promises.types';
import {
  CompanyItemType,
  GetParamsCompanyType,
} from '@/shared/api/compnaies/compnaies.api.types';
import CompaniesDataApi from '@/shared/api/compnaies/compnaies.api';

const WebsitesContainer = () => {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);

  const [queries, setQueries] = useQueryParams<TGetWebsitesParams>({});
  const [offset] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);

  const [websites, setWebsites] = useState<WebsiteItemType[]>([]);
  const [companies, setCompanies] = useState<CompanyItemType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(
    queries.openModal === 'true',
  );
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<WebsiteItemType | null>(
    null,
  );
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyItemType | null>(null);
  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const [companiesParams, setCompaniesParams] = useState<GetParamsCompanyType>({
    query: '',
    pageCount: '30',
  });

  const onSetSelectedItem = (id: string | number) => {
    setSelectedItem(websites.find(item => item.id === id) || null);
  };

  const handleCloseModals = () => {
    setOpenModal(false);
    setRemoveModal(false);
    setLoadingForm(false);
    setSelectedItem(null);
    setQueries({ ...queries, openModal: '' });
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedItem(null);
  };

  const handleOpenRemoveModal = (id: string | number) => {
    setRemoveModal(true);
    setOpenModal(true);
    onSetSelectedItem(id);
  };

  const handleOpenEditModal = async (id: string | number) => {
    const currentWebsite = websites.find(item => item.id === id);

    if (currentWebsite && currentWebsite.company_id) {
      await fetchCompanyById(currentWebsite.company_id);
    }

    setOpenModal(true);
    onSetSelectedItem(id);
  };

  const fetchWebsites = async () => {
    setLoading(true);

    try {
      const { data, total_items } = await WebsiteDataApi.getWebsites({
        ...queries,
        pageCount: `${offset}`,
      });

      const total = total_items ? total_items : 0;

      setPages(Math.ceil(total / offset));

      setWebsites(data);

      setEmptyRequests(prev => prev + 1);
    } catch (error) {
      setNotify({ message: 'Error while fetching websites', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = async () => {
    setLoadingCompanies(true);

    try {
      const { data, total_items } =
        await CompaniesDataApi.fetchCompaniesList(companiesParams);

      const total = total_items ? total_items : 0;

      setPages(Math.ceil(total / +offset));

      setCompanies(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCompanies(false);
    }
  };

  const fetchCompanyById = async (id: string | number) => {
    try {
      const { data } = await CompaniesDataApi.fetchCompanyById(id);

      setSelectedCompany(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchCompanies = (query: string) => {
    setCompanies([]);

    setCompaniesParams({ ...companiesParams, query });
  };

  const onSearchWebsites = (query: string) => {
    if (query.length === 0 && epmtyRequests !== 1) {
      setQueries({ ...queries, query: undefined, page: '1' });
      return;
    }

    if (query.length >= 3) {
      setQueries({ ...queries, query, page: '1' });
      return;
    }
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  const onRemoveItem = async () => {
    setLoadingForm(true);

    try {
      if (selectedItem) {
        await WebsiteDataApi.deleteWebsite(selectedItem.id);

        setWebsites(websites.filter(item => item.id !== selectedItem.id));

        setRemoveModal(false);
        setSelectedItem(null);
        setOpenModal(false);
      }
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingForm(false);
    }
  };

  const onRefreshItem = async (id: Tid, url: string) => {
    setLoadingForm(true);

    try {
      await WebsiteDataApi.refreshWebsite(id, url);

      setWebsites(prev =>
        prev.map(item => {
          if (item.id === id) {
            return {
              ...item,
              crawler_status: 'processing',
            };
          }
          return item;
        }),
      );

      await fetchWebsites();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingForm(false);
    }
  };

  const onEditWebsite = async (form: TWebsiteUpdateForm) => {
    setLoadingForm(true);

    try {
      await WebsiteDataApi.updateWebsite(form);

      setWebsites([]);

      setOpenModal(false);
      setSelectedItem(null);

      await fetchWebsites();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingForm(false);
    }
  };

  const onCreateWebsite = async (form: WebsiteFormType) => {
    if (selectedItem) {
      return;
    }

    setLoadingForm(true);

    try {
      await WebsiteDataApi.createWebsite(form);

      setWebsites([]);

      setOpenModal(false);

      await fetchWebsites();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    fetchWebsites();
  }, [queries]);

  useEffect(() => {
    fetchCompanies();
  }, [companiesParams]);

  const memoizedWebsites = useMemo(() => websites, [websites]);

  return (
    <ContentLayout
      title="Websites"
      buttonTitle="Create new website"
      loading={loading}
      formLoading={loadingForm}
      openModal={openModal}
      isRemoveModal={removeModal}
      selectedItem={selectedItem}
      removeTitleModal="Remove website"
      removeTitleMessage={`Are you sure you want to remove this website ${selectedItem?.name}?`}
      onCreate={handleOpenCreateModal}
      onCloseModal={handleCloseModals}
      onRemoveItem={onRemoveItem}
      form={
        <WebsiteFrom
          loading={loadingForm}
          loadingCompany={loadingCompanies}
          website={selectedItem}
          company={selectedCompany}
          companies={companies}
          onCancel={handleCloseModals}
          onCreate={onCreateWebsite}
          onUpdate={onEditWebsite}
          onSearchCompanies={handleSearchCompanies}
        />
      }
    >
      <SearchBlock
        value={queries.query || ''}
        placeholder="Search website by name"
        onInput={onSearchWebsites}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />

      <WebsitesList
        websites={memoizedWebsites}
        pages={pages}
        params={queries}
        loading={loading}
        onDelete={handleOpenRemoveModal}
        onEdit={handleOpenEditModal}
        onChangePage={onChangePage}
        onRefresh={onRefreshItem}
      />
    </ContentLayout>
  );
};

export default WebsitesContainer;
