import { TRoute } from '../../router/router.type';
import Companies from './companies.view';
import Company from './features/company/company.view';

const routes: TRoute[] = [
  {
    path: '/companies',
    name: 'companies',
    title: 'Companies',
    component: function () {
      return <Companies />;
    },
    exact: true,
  },

  {
    path: '/companies/:id',
    name: 'company',
    title: 'Company',
    component: function () {
      return <Company />;
    },
  },
];

export default routes;
