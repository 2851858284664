import { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  SelectChangeEvent,
  //   Box,
  //   Button,
} from '@mui/material';

// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Tid } from '@/shared/types/promises.types';

import FormModal from '@/shared/components/Forms/FormModal';

import useValidate, {
  Rules,
  emailPattern,
} from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';
import { TFormParams, TUserItem } from '../types';

import roles, { RolesTypes, RoleType } from '../roles';
import { useAuthentication } from '@/shared/context/authContext';

type Props = {
  user?: TUserItem | null | undefined;
  loading: boolean;
  onCreate: (form: TFormParams) => void;
  onUpdate: (id: Tid, form: TFormParams) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
};

const CategoryForm = (props: Props) => {
  const { user, onCancel, onCreate, onUpdate, loading } = props;

  const { user: currentUser } = useAuthentication();

  const filtredRoles: RolesTypes[] = roles.filter(role => {
    if (
      currentUser?.role === 'Super Admin' ||
      currentUser?.role === 'superadmin'
    ) {
      return role.name !== 'All';
    } else {
      return role.name === 'User' || role.name === 'Company manager';
    }
  });

  const [isEdit] = useState<boolean>(!!user);

  const [form, setForm] = useState<TFormParams>({
    email: user?.email || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    role_id: filtredRoles.find(role => role.value === user?.role)?.id || 1,
    status: user?.status || 'active',
  });

  const rules: Rules<TFormParams> = {
    email: {
      required: true,
      emailPattern,
    },
    first_name: {
      required: true,
      minLength: 2,
      maxLength: 50,
    },
    last_name: {
      required: true,
      minLength: 2,
      maxLength: 50,
    },
    role_id: {
      required: true,
    },
    status: {
      required: true,
    },
  };

  const { dirty, touch, errors } = useValidate<TFormParams>(form, rules);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    if (touch()) {
      return;
    }

    if (isEdit && user) {
      onUpdate(user.id, form);
    } else {
      onCreate(form);
    }
  };

  const onInput = (
    value: string,
    type: 'first_name' | 'last_name' | 'email',
  ) => {
    setForm(prevForm => ({
      ...prevForm,
      [type]: value,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectStatusType = (value: TFormParams['status']) => {
    setForm(prevForm => ({
      ...prevForm,
      status: value,
    }));
  };

  const onSelectUserType = (event: SelectChangeEvent<RoleType>) => {
    const selectedRole = roles.find(role => role.name === event.target.value);
    const roleId = selectedRole ? selectedRole.id : null;

    setForm(prevForm => ({
      ...prevForm,
      role_id: roleId || 0,
    }));
  };

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} User`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <TextField
          placeholder="First Name"
          value={form.first_name}
          name="first_name"
          error={dirty && errors.first_name.dirty}
          helperText={dirty && mappingErrors(errors?.first_name?.message)}
          onInput={event =>
            onInput(
              (event?.target as HTMLInputElement)?.value || '',
              'first_name',
            )
          }
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          value={form.last_name}
          name="last_name"
          placeholder="Last Name"
          error={dirty && errors.last_name.dirty}
          helperText={dirty && mappingErrors(errors?.last_name?.message)}
          onInput={event =>
            onInput(
              (event?.target as HTMLInputElement)?.value || '',
              'last_name',
            )
          }
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          placeholder="Email"
          value={form.email}
          name="email"
          error={dirty && errors.email.dirty}
          helperText={dirty && mappingErrors(errors?.email?.message)}
          onInput={event =>
            onInput((event?.target as HTMLInputElement)?.value || '', 'email')
          }
        />
      </FormControl>

      <Select
        value={filtredRoles.find(role => role.id === form.role_id)?.name || ''}
        label="Role"
        sx={{ marginBottom: 4, minWidth: 200 }}
        onChange={onSelectUserType}
      >
        {filtredRoles.map(role => (
          <MenuItem key={role.id} value={role.name}>
            {role.name}
          </MenuItem>
        ))}
      </Select>

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={form.status}
        onChange={(_, value) =>
          onSelectStatusType(value as TFormParams['status'])
        }
      >
        <FormControlLabel value="active" control={<Radio />} label="Active" />
        <FormControlLabel
          value="disabled"
          control={<Radio />}
          label="Disabled"
        />
      </RadioGroup>
    </FormModal>
  );
};

export default CategoryForm;
