import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import UserItem from './UserItem';
import TableElements from '@/shared/components/StyledTableCell';
import Pagination from '@/shared/components/Pagination';

import { TUserItem, TGetUserParams } from '../types';
import { Tid } from '@/shared/types/promises.types';

type Props = {
  users: TUserItem[];
  params: TGetUserParams;
  loading: boolean;
  pages: number;
  onSort: (field: TGetUserParams['order_by']) => void;
  onDelete: (id: Tid) => void;
  onEdit: (id: Tid) => void;
  onChangePage: (page: string) => void;
};

export default function UsersList(props: Props) {
  const {
    users,
    loading,
    params,
    pages,
    onSort,
    onDelete,
    onEdit,
    onChangePage,
  } = props;

  const checkSortDirection = (field: TGetUserParams['order_by']) => {
    if (params.order_by?.includes(field as string)) {
      return params.order_by.includes('-') ? 'desc' : 'asc';
    }

    return 'desc';
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell
                sortable
                sortDirection={checkSortDirection('first_name')}
                onClick={() => onSort('first_name')}
              >
                Name
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Email
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center">
                Role
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell
                align="center"
                sortable
                sortDirection={checkSortDirection('status')}
                onClick={() => onSort('status')}
              >
                Status
              </TableElements.StyledTableCell>

              <TableElements.StyledTableCell align="center"></TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              users.map(user => (
                <UserItem
                  key={user.id}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  {...user}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pages={pages}
        page={Number(params.page) || 1}
        onChange={(_, value) => onChangePage(String(value))}
      />
    </>
  );
}
