import { TRoute } from '../../router/router.type';
import Notifications from './notifications.view';
import NotificationItem from './features/notification/notification.view';

const routes: TRoute[] = [
  {
    path: '/notifications',
    name: 'Notifications',
    title: 'Notifications',
    component: function () {
      return <Notifications />;
    },
    exact: true,
  },

  {
    path: '/notifications/:id',
    name: 'Notification',
    title: 'Notification',
    component: function () {
      return <NotificationItem />;
    },
    exact: true,
  },
];

export default routes;
