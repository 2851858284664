import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CompanyItem from './CompanyItem';
import TableElements from '@/shared/components/StyledTableCell';
import LoadingTable from '@/shared/components/LoadingTable';
import Pagination from '@/shared/components/Pagination';

import {
  CompanyItemType,
  GetParamsCompanyType,
} from '@/shared/api/compnaies/compnaies.api.types';

type Props = {
  companies: CompanyItemType[];
  params: GetParamsCompanyType;
  loading: boolean;
  pages: number;
  onEdit: (id: string | number) => void;
  onDelete: (id: string | number) => void;
  onSort: (field: string) => void;
  onChangePage: (page: string) => void;
  onRetrieve: (name: string) => void;
};

export default function CompnaiesList(props: Props) {
  const {
    companies,
    loading,
    params,
    pages,
    onEdit,
    onDelete,
    onSort,
    onChangePage,
    onRetrieve,
  } = props;

  const checkSortDirection = (field: string) => {
    if (params.order_by?.includes(field)) {
      return params.order_by.includes('-') ? 'desc' : 'asc';
    }

    return 'desc';
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableElements.StyledTableCell
                sortable
                sortDirection={checkSortDirection('name')}
                onClick={() => onSort('name')}
              >
                Name
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                URL
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Email
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Phone
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Address
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center">
                Status
              </TableElements.StyledTableCell>
              <TableElements.StyledTableCell align="center"></TableElements.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <LoadingTable />
            ) : (
              companies.map(company => (
                <CompanyItem
                  key={company.id}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onRetrieve={onRetrieve}
                  {...company}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        pages={pages}
        page={Number(params.page) || 1}
        onChange={(_, value) => onChangePage(String(value))}
      />
    </>
  );
}
